import * as React from 'react';
import {MessageBar, MessageBarType, IContextualMenuItem, Link} from 'office-ui-fabric-react';
import {DetailsListUtils, Log, Util, Toast, OpalTable} from "@voxfp/opal_ui_common";
import {SmartRepeatService} from '../../services/smartRepeatService';
import {WorkflowEventService} from '../../services/workflowEventService';

export interface AppProps {
    repeatId?: number;
    name?: string;
    type: string;
    history: any;
    location?: any;
    permissions: any;
}

export interface AppState {
    listItems: Array<any>;
    columns: Array<any>;
    isDataLoaded: boolean;
    isError: boolean;
}

export class SmartRepeatList extends React.Component<AppProps, AppState>  {

    smartRepeatService: SmartRepeatService = new SmartRepeatService();
    workflowEventService: WorkflowEventService = new WorkflowEventService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    reset() {
        return ({
            listItems: [],
            columns: [],
            isDataLoaded: false,
            isError: false
        });
    }

    componentDidMount() {
        this.loadList();
    }

    componentDidUpdate(i) {
        let newPath = i.history.location.pathname;
        if (newPath !== i.location.pathname) {
            this.loadList();
        } 
    }

    loadList() {
        if (this.props.repeatId > 0) {
            this.loadRepeatRevisionsList();
        }
        else {
            this.loadRepeatList();
        }
    }

    loadRepeatList() {
        this.fetchSmartRepeats();
        this.setRepeatColumns();
    }

    loadRepeatRevisionsList() {
        this.setState({
            listItems: []
        });
        this.fetchSmartRepeatRevisions(this.props.repeatId);
        this.setRepeatRevisionsColumns();
    }

    fetchSmartRepeats() {
        Log.debug("Fetching Repeats", this.state);
        this.smartRepeatService.fetchSmartRepeats().then((items: any) => {
            DetailsListUtils.sortItems(items, "updatedAt", true);
            this.setState({
                listItems: items,
                isDataLoaded: true,
                isError: false
            });         
        }).catch(error => {
            this.setState({
                isDataLoaded: true,
                isError: true
            });
            Util.showToast(new Toast('Error fetching Repeats. ' + error, MessageBarType.error));          
        });
    }

    fetchSmartRepeatRevisions(id) {
        Log.debug("Fetching Repeat Revisions", this.state);
        this.smartRepeatService.fetchSmartRepeatRevisions(id).then((items: any) => {
            DetailsListUtils.sortItems(items, "updatedAt", true);
            this.setState({
                listItems: items,
                isDataLoaded: true,
                isError: false
            });        
        }).catch(error => {
            this.setState({
                isDataLoaded: true,
                isError: true
            });
            Util.showToast(new Toast('Error fetching Repeat Revisions. ' + error, MessageBarType.error));          
        });
    }

    deleteItem(item) {
        Log.debug("Deleting Repeat", item);
        this.smartRepeatService.deleteSmartRepeat(item.details.id).then((_data) => {
            Util.showToast(new Toast('Repeat deleted.', MessageBarType.success));
            this.loadRepeatRevisionsList();
        }).catch(error => {
            Log.error("Error deleting Repeat", error);
            Util.showToast(new Toast('Error deleting Repeat. ' + error, MessageBarType.error, 4000));
        });
    }

    setRepeatColumns() {
        const createAbility = this.props.permissions.can("create", "control");
        const templateListColumns = [
            {
                field: "label",
                headerText: "Name",
                width: "15",
                itemType: "Link",
                customAttributes: {
                    urlTemplate: "#/repeats//details/{label}/{id}"
                }
            },
            {
                field: "smartRepeatType",
                headerText: "Type",
                width: "8",
                filter: "Checkbox"
            },
            {
                field: "playbook",
                headerText: "Playbook",
                width: "10",
                filter: "Checkbox"
            },
            {
                field: "currentRevision.version",
                headerText: "Version",
                width: "10",
                filter: "Checkbox"
            },
            {
                field: "currentRevision.workflow_state",
                headerText: "Status",
                width: "10",
                filter: "Checkbox"
            },
            {
                field: "updatedAt",
                headerText: "Last Edit",
                width: "10",
                type: "date",
                format: "yyyy-MM-dd"
            },
            {
                field: "options",
                headerText: "Options",
                width: "15",
                disableSorting: true,
                disableFiltering: true,
                itemType: "Dropdown",
                customAttributes: {
                    buttonText: "Options",
                    dropdownTemplate: [{
                        sectionProps: [{
                            title: 'View',
                            items: [
                                {
                                    text: 'Version History',
                                    onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                        this.handleVersionHistoryClick(i.item);
                                    }
                                }
                            ]
                        },
                        {
                            title: createAbility ? 'Manage' : '',
                            items: [
                                {
                                    onRender: (i: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
                                        return (
                                            createAbility &&
                                            <Link disabled={i.item.currentRevision["workflow_state"] === "Draft" ? true : false} onClick={() => {
                                                this.handleNewRevisionClick(i.item);
                                                dismissMenu();
                                            }} className="opal-ContextualMenu-link">
                                                <div className="opal-ContextualMenu-linkContent">
                                                    <span className={i.item.currentRevision["workflow_state"] === "Draft" ? "opal-ContextualMenu-itemText disabled" : "opal-ContextualMenu-itemText"}>
                                                        New Revision
                                                    </span>
                                                </div>
                                            </Link>
                                        );
                                    }
                                }
                            ]
                        }]
                    }]
                }   
            }
        ];
        this.setState({
            columns: templateListColumns
        });
    }

    setRepeatRevisionsColumns() {
        const readAbility = this.props.permissions.can("read", "control");
        const templateListColumns = [
            {
                field: "version",
                headerText: "Version",
                width: "15",
                itemType: "Link",
                customAttributes: {
                    urlTemplate: "#/repeats//revisions/" + encodeURIComponent(this.props.name) + "/" + this.props.repeatId + "//revisiondetails/{version}/{details.id}",
                    linkTextTemplate: "Version {version}"
                }
            },

            {
                field: "author",
                headerText: "Author",
                width: "20",
                filter: "Checkbox"
            },
            {
                field: "updatedAt",
                headerText: "Last Edit",
                width: "20",
                type: "date",
                format: "yyyy-MM-dd"
            },
            {
                field: "templatesCount",
                headerText: "Templates",
                width: "15",
            },
            {
                field: "workflowState",
                headerText: "State",
                width: "15",
                filter: "Checkbox"
            },
            {
                field: "options",
                headerText: readAbility ? "Options" : "",
                width: readAbility ? "15" : "0",
                disableSorting: true,
                disableFiltering: true,
                itemType: "Dropdown",
                customAttributes: {
                    buttonText: "Options",
                    dropdownTemplate: [{
                        sectionProps: [{
                            title: 'Actions',
                            items: [
                                {
                                    onRender: (i: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
                                        return (
                                            i.item.workflowEvents.map(
                                                (workflowItem, _key) => {
                                                return (
                                                        <Link onClick={() => {
                                                            this.handleWorkflowState(i.item.id, workflowItem.name);
                                                            dismissMenu();
                                                        }} className="opal-ContextualMenu-link">
                                                            <div className="opal-ContextualMenu-linkContent">
                                                                <span className="opal-ContextualMenu-itemText">
                                                                    {workflowItem.description}
                                                                </span>
                                                            </div>
                                                        </Link>
                                                    );
                                                }
                                            )
                                        );
                                    }
                                }
                            ]
                        }]
                    }]
                }  
            }
        ];
        this.setState({
            columns: templateListColumns
        });
    }

    handleVersionHistoryClick(item) {
        let name = encodeURIComponent(item.label);
        this.props.history.push("/repeats//revisions/" + name + "/" + item.id);
    }

    handleNewRevisionClick(i) {
        this.getSmartRepeat(i.id).then((smartRepeat) => {
            this.createSmartRepeatRevision(smartRepeat).then((item: any) => {
                window.location.assign("#/repeats//revisions/" + encodeURIComponent(item.label) + "/" + item.id + "//revisiondetails/" + item.currentRevision.version + "/" + item.id);
            });
        });
    }

    getSmartRepeat(id) {
        return new Promise((resolve, reject) => {
            this.smartRepeatService.getSmartRepeat(id).then((smartRepeat: any) => {
                resolve(smartRepeat);
            }).catch(error => {
                Log.error("Error fetching Repeat ID=" + id, error);
                Util.showToast(new Toast('Error fetching Repeat. ' + error, MessageBarType.error));
                reject(error);
            });
        });
    }

    createSmartRepeatRevision(item) {
        return new Promise((resolve, reject) => {
            let smartRepeat = {   
                playbook_id: item.playbookId,         
                label: item.label,
                friendly_name: item.friendlyName,
                smart_repeat_type_id: item.smartRepeatTypeId,
                template_revision_id: item.templateRevision.id
            };
            this.smartRepeatService.saveSmartRepeat(smartRepeat).then((success) => {  
                resolve(success);
            }).catch(error => {
                Util.showToast(new Toast('Error creating a revision. ' + error, MessageBarType.error, 4000));
                reject(error);
            });
        });
    }

    handleWorkflowState(id, workflowEvent) {
        this.setItemState(id, workflowEvent);
    }

    setItemState(id, state) {
        this.workflowEventService.setWorkflowEvent(id, state).then((_data) => {
            Util.showToast(new Toast('Template workflow set to ' + state + '.', MessageBarType.success));
            this.loadList();
        })
        .catch((error) => {
            Util.showToast(new Toast('Error setting workflow. ' + error, MessageBarType.error));
        });
    }

    render() {

        Log.debug('Rendering Repeats List', this.state);

        return (
            <div className="mt1">
                {this.state && this.state.listItems.length > 0 && this.state.columns !== [] &&
                    <OpalTable
                        listData={this.state && this.state.listItems}
                        listColumns={this.state && this.state.columns}
                        allowPaging={true}
                        pageSettings={{
                            pageCount: 5,
                            pageSizes: true,
                            pageSize: 10
                        }}
                    />
                }
                {((this.state && this.state.isError) || (this.state && this.state.listItems.length === 0)) && this.state.isDataLoaded &&
                    <MessageBar>There are no items to show.</MessageBar>
                }
            </div>
        );
    }
}

export default SmartRepeatList;
