import {Model} from "@voxfp/opal_ui_common";
import { SmartRule } from "./smartRule";
import { CurrentRevision } from "./currentRevision";

export class SmartToken extends Model<SmartToken> {

    id?: number;
    label?: string;
    friendlyName?: string;
    playbookName?: string;
    playbookId?: number;
    updatedAt?: any;
    smartRule?: SmartRule;
    currentRevision?: CurrentRevision;
    revision?: CurrentRevision;

    constructor(id?: number, label?: string, friendlyName?: string,
                playbookName?: string, playbookId?: number, updatedAt?: any,
                smartRule?: SmartRule, currentRevision?: CurrentRevision,
                revision?: CurrentRevision) {
        super(SmartToken);
        this.id = id;
        this.label = label;
        this.friendlyName = friendlyName;
        this.playbookName = playbookName;
        this.playbookId = playbookId;
        this.updatedAt = updatedAt;
        this.smartRule = smartRule;
        this.currentRevision = currentRevision;
        this.revision = revision;
    }
}
