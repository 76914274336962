import * as React from 'react';
import { Log, Util, Toast, SwitchBuilder, SwitchValue } from "@voxfp/opal_ui_common";
import { TextField, Dropdown, Label, PrimaryButton, DefaultButton, MessageBar, MessageBarType, Dialog, DialogFooter, IDropdownOption } from 'office-ui-fabric-react';
import { PlaybookService } from '../../services/playbookService';
import { SmartSwitchService } from '../../services/smartSwitchService';
import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";

export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
    smartSwitchId?: number;
    type?: string;
}

export interface AppState {
    playbooks: Array<any>;
    playbookId: number;
    playbook: string;
    contentControls: Array<any>;
    switch: Array<SwitchValue>;
    name: string;
    description: string;
    templates: Array<any>;
    defaultContentControlRevisionId: any;
    caseContentControlRevisionId: number;
    contentControlError: boolean;
    smartTemplatesError: boolean;
    saveError: boolean;
    errorMessage: string;
    hideCancelDialog: boolean;
    hideSavedDialog: boolean;
    formValid: boolean;
    status: string;
    loading: boolean;
}

export class SmartSwitchEditor extends React.Component<AppProps, AppState>  {

    playbookService: PlaybookService = new PlaybookService();
    smartSwitchService: SmartSwitchService = new SmartSwitchService();

    private _switchValues: Array<SwitchValue>;

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
        this._switchValues = [];
        this.handleQueryChange = this.handleQueryChange.bind(this);
    }

    componentDidMount() {
        this.fetchPlaybooks();
        if (this.props.smartSwitchId) {
            const id = this.props.smartSwitchId;
            this.getSmartSwitch(id);
        }
    }

    fetchPlaybooks() {
        this.setState({
            loading: true
        });
        this.playbookService.fetchPlaybooks(true, true, null, null, null).then((playbooks: any) => {
            this.setState({
                playbooks: playbooks
            });
            this.setState(prevState => ({
                playbooks: [{ key: 0, text: "Select a Playbook", disabled: true }, ...prevState.playbooks]
            }));
        }).catch(error => {
            Log.error("Error fetching Playbooks", error);
            Util.showToast(new Toast('Error fetching Playbooks.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    getSmartSwitch(id) {
        this.setState({
            loading: true
        });
        this.smartSwitchService.getSmartSwitch(id).then((smartSwitch: any) => {
            this.setState({
                name: smartSwitch.label,
                description: smartSwitch.friendlyName,
                switch: smartSwitch.switch,
                playbookId: smartSwitch.playbookId,
                playbook: smartSwitch.playbook,
                status: smartSwitch.currentRevision["workflow_state"],
                caseContentControlRevisionId: smartSwitch.caseContentControlRevisionId.toString(),
                defaultContentControlRevisionId: smartSwitch.defaultContentControlRevisionId ? smartSwitch.defaultContentControlRevisionId.toString() : 0
            });
            this._switchValues = smartSwitch.switch;
            this.fetchContentControlRevisions(this.state.playbookId);
            this.fetchSmartTemplateRevisions(this.state.playbookId);
        this.fetchPlaybooks();

        }).catch(error => {
            Log.error("Error fetching Switch ID=" + id, error);
            Util.showToast(new Toast('Error fetching Switch.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    fetchContentControlRevisions(playbookId) {
        this.setState({
            loading: true
        });
        this.playbookService.fetchComposableContentControlRevisionsByTypeFromPlaybook(playbookId, "SmartToken").then((data: any) => {
            this.setState({
                contentControls: data,
                contentControlError: false
            });
            this.setState(prevState => ({
                contentControls: [{ key: 0, text: "Select a Content Control", disabled: true }, ...prevState.contentControls]
            }));
        }).catch(error => {
            Log.error('Error fetching Content Controls from PlaybookId=' + playbookId, error);
            this.setState({
                contentControls: [],
                contentControlError: true
            });
            Util.showToast(new Toast('Error fetching Content Controls from Playbook.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    fetchSmartTemplateRevisions(playbookId) {
        this.setState({
            loading: true
        });
        this.playbookService.fetchComposableContentControlRevisionsByTypeFromPlaybook(playbookId, "SmartTemplate").then((data: any) => {
            this.setState({
                templates: data,
                smartTemplatesError: false
            });
            this.setState(prevState => ({
                templates: [{ key: 0, text: this.state.status === "Draft" || this.props.type === "create" ? "Select a Template" : "", disabled: true }, ...prevState.templates]
            }));
        }).catch(error => {
            Log.error('Error fetching Sub-Templates from PlaybookId=' + playbookId, error);
            this.setState({
                contentControls: [],
                smartTemplatesError: true
            });
            Util.showToast(new Toast('Error fetching Sub-Templates from Playbook.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    handleNameInput(e) {
        this.setState({
            name: e.target.value
        });
    }

    handleDescriptionInput(e) {
        this.setState({
            description: e.target.value
        });
    }

    handlePlaybookChange(playbook) {
        this.setState({
            playbookId: playbook.key,
            playbook: playbook.text
        });
        this.fetchContentControlRevisions(playbook.key);
        this.fetchSmartTemplateRevisions(playbook.key);
    }

    validateForm() {
        if (this.state.playbookId !== null && this.state.playbookId > 0 && this.state.caseContentControlRevisionId !== null && this.state.caseContentControlRevisionId > 0 && this.state.name.length > 0 && this.state.description.length > 0) {
            this.setState({
                formValid: true
            });
            return true;
        }
        else {
            this.setState({
                formValid: false
            });
            return false;
        }
    }

    handleCaseContentControlChange(e) {
        const id = e.key;
        this.setState({
            caseContentControlRevisionId: id
        });
    }

    handleDefaultContentContolChange(e) {
        const id = e.key;
        this.setState({
            defaultContentControlRevisionId: id
        });
    }

    handleQueryChange(queryValue) {
        this._switchValues = queryValue;
    }

    saveSwitch(revision?) {
        return new Promise((resolve, reject) => {
            let isValid = this.validateForm();
            if (isValid) {
                let smartSwitch = {
                    playbook_id: this.state.playbookId,
                    label: this.state.name,
                    name: this.state.description,
                    description: this.state.description,
                    switch: this._switchValues,
                    caseContentControlRevisionId: this.state.caseContentControlRevisionId,
                    defaultContentControlRevisionId: this.state.defaultContentControlRevisionId === 0 ? null : this.state.defaultContentControlRevisionId
                };

                Log.debug("Saving switch", smartSwitch);
                this.smartSwitchService.saveSmartSwitch(smartSwitch).then((success) => {
                    if (!revision) {
                        this.showSavedDialog();
                    }
                    resolve(success);
                }).catch(error => {
                    this.setState({
                        saveError: true,
                        errorMessage: error + ". "
                    });
                    Util.showToast(new Toast('Error saving Switch. ' + error, MessageBarType.error, 4000));
                    reject(error);
                });

            }
            else {
                Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
                reject();
            }
        });
    }

    newRevision() {
        this.saveSwitch("revision").then((item: any) => {
            window.location.assign("#/switches//revisions/" + encodeURIComponent(item.label) + "/" + item.id + "//revisiondetails/" + item.currentRevision.version + "/" + item.id);
            this.getSmartSwitch(item.id);
        });
    }

    updateSmartSwitch() {
        let isValid = this.validateForm();
        if (isValid) {
            let smartSwitch = {
                playbook_id: this.state.playbookId,
                label: this.state.name,
                name: this.state.description,
                description: this.state.description,
                switch: this._switchValues,
                caseContentControlRevisionId: this.state.caseContentControlRevisionId,
                defaultContentControlRevisionId: this.state.defaultContentControlRevisionId === 0 ? null : this.state.defaultContentControlRevisionId
            };
            this.smartSwitchService.updateSmartSwitch(this.props.smartSwitchId, smartSwitch).then((_success) => {
                this.showSavedDialog();
            }).catch(error => {
                this.setState({
                    saveError: true,
                    errorMessage: error + ". "
                });
                Util.showToast(new Toast('Error saving Switch. ' + error, MessageBarType.error, 4000));
            });

        }
        else {
            Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
        }
    }

    cancel() {
        this.setState({
            hideCancelDialog: false
        });
    }

    closeDialog() {
        this.setState({
            hideCancelDialog: true
        });
    }

    showSavedDialog() {
        this.setState({
            hideSavedDialog: false
        });
    }

    okContinue() {
        window.location.assign('#/switches');
    }

    reset() {
        return {
            playbooks: (this.state && this.state.playbooks) || [],
            name: '',
            description: '',
            contentControlError: false,
            smartTemplatesError: false,
            playbookId: null,
            playbook: '',
            contentControls: null,
            templates: null,
            defaultContentControlRevisionId: 0,
            caseContentControlRevisionId: 0,
            switch: null,
            hideCancelDialog: true,
            hideSavedDialog: true,
            saveError: false,
            errorMessage: "",
            formValid: null,
            status: "",
            loading: false
        };
    }

    render() {
        Log.debug('Rendering Create New Switch', this.state);
        return (

            <div className="mt1">

                <div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">

                            <TextField
                                readOnly={(this.props.smartSwitchId && this.state.status === "Draft") || this.props.type === "create" ? false : true}
                                className={"mb05"}
                                label="Name"
                                id="name"
                                errorMessage={this.state && this.state.name.length === 0 && this.state.formValid === false ? "* required" : null}
                                value={this.state.name}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                    this.handleNameInput(event);
                                }}
                            />
                            <TextField
                                readOnly={(this.props.smartSwitchId && this.state.status === "Draft") || this.props.type === "create" ? false : true}
                                className={"mb05"}
                                label="Description"
                                id="description"
                                errorMessage={this.state && this.state.description.length === 0 && this.state.formValid === false ? "* required" : null}
                                value={this.state.description}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                    this.handleDescriptionInput(event);
                                }}
                            />
                            <Label>Playbook</Label>
                            <Dropdown responsiveMode={2}
                                disabled={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? false : true}
                                className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? "mb05" : "readOnlyStyle mb05"}
                                placeholder=""
                                notifyOnReselect={true}
                                id='playbook'
                                ariaLabel='Playbook'
                                defaultSelectedKey={this.state.playbookId ? this.state.playbookId.toString() : 0}
                                options={this.state && this.state.playbooks ? this.state.playbooks : []}
                                onChange={
                                    (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                        this.handlePlaybookChange(item);
                                    }
                                }
                            />
                            {this.state && this.state.contentControlError === true &&
                                <div className="mb05">
                                    <MessageBar messageBarType={MessageBarType.error}>Selected playbook does not have any content controls.</MessageBar>
                                </div>
                            }
                            {this.state && this.state.smartTemplatesError === true &&
                                <div className="mb05">
                                    <MessageBar messageBarType={MessageBarType.error}>Selected playbook does not have any smart templates.</MessageBar>
                                </div>
                            }

                        </div>
                    </div>
                    {this.state && this.state.contentControls &&
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                                <Label>Switch</Label>
                                <Dropdown responsiveMode={2}
                                    disabled={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? false : true}
                                    className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? "mb05" : "readOnlyStyle mb05"}
                                    placeholder=''
                                    notifyOnReselect={true}
                                    id='contentControlSwitch'
                                    ariaLabel='Content Control to use as Switch'
                                    defaultSelectedKey={this.state.caseContentControlRevisionId}
                                    options={this.state && this.state.contentControls ? this.state.contentControls : []}
                                    onChange={
                                        (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                            this.handleCaseContentControlChange(item);
                                        }
                                    }
                                    errorMessage={this.state && this.state.caseContentControlRevisionId === 0 && this.state.formValid === false ? "* required" : null}
                                />

                            </div>
                        </div>
                    }
                    {this.state && this.state.templates && this.state.caseContentControlRevisionId > 0 &&
                        <div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md8">
                                    <div className="switch">

                                        <div className="switchItem">
                                            <div className="ms-Grid-row">
                                                <div className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? "ms-Grid-col ms-sm12 ms-md7" : "ms-Grid-col ms-sm12 ms-md8"}>
                                                    <TextField
                                                        readOnly
                                                        value="Default"
                                                        className="readOnlyStyle"
                                                    >
                                                    </TextField>
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md4">
                                                    <Dropdown responsiveMode={2}
                                                        disabled={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? false : true}
                                                        className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? "mb05" : "readOnlyStyle mb05"}
                                                        placeholder=''
                                                        notifyOnReselect={true}
                                                        id='templateTrue'
                                                        ariaLabel='Template to use if switch statement is true'
                                                        defaultSelectedKey={this.state.defaultContentControlRevisionId}
                                                        options={this.state ? this.state.templates : []}
                                                        onChange={
                                                            (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                                                this.handleDefaultContentContolChange(item);
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {this.state && this.state.templates.length > 0 &&
                                            <SwitchBuilder
                                                templates={this.state && this.state.templates ? this.state.templates : []}
                                                rules={this.state && this.state.switch}
                                                onChange={this.handleQueryChange}
                                                readOnly={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? false : true}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md8">
                                    {this.props.type === "create" &&
                                        <div className="buttonRow right">
                                            <DefaultButton text="Cancel" className="mt1" onClick={this.cancel.bind(this)}></DefaultButton>
                                            <PrimaryButton iconProps={{ iconName: "Save" }} className="mt1" text="Save" onClick={() => { this.saveSwitch(); }}></PrimaryButton>
                                        </div>
                                    }

                                    {this.props.smartSwitchId && this.state.status !== "Draft" &&
                                        <Can do="edit" on="control">
                                            <div className="buttonRow right">
                                                <PrimaryButton iconProps={{ iconName: "Tab" }} className="mt1" text="New Revision" onClick={() => { this.newRevision(); }}></PrimaryButton>
                                            </div>
                                        </Can>
                                    }

                                    {this.state && this.state.status === "Draft" && (this.props.type === "details" || this.props.type === "revisiondetails") &&
                                        <Can do="edit" on="control">
                                            <div className="buttonRow right">
                                                <DefaultButton text="Cancel" className="mt1" onClick={this.cancel.bind(this)}></DefaultButton>
                                                <PrimaryButton iconProps={{ iconName: "Save" }} className="mt1" text="Update" onClick={() => { this.updateSmartSwitch(); }}></PrimaryButton>
                                            </div>
                                        </Can>
                                    }
                                    {this.state && this.state.saveError === true &&
                                        <div>
                                            <br />
                                            <MessageBar messageBarType={MessageBarType.error}>Error saving Switch. {this.state.errorMessage}Please check form and try again.</MessageBar>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    }
                </div>



                <Dialog
                    hidden={this.state && this.state.hideCancelDialog === null ? true : this.state && this.state.hideCancelDialog}
                    onDismiss={this.closeDialog.bind(this)}
                    dialogContentProps={{
                        title: 'Cancel changes',
                        subText: 'This will remove all changes. Are you sure you want to cancel?'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <DialogFooter>
                        <DefaultButton onClick={this.closeDialog.bind(this)} text="No" />
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Yes" />
                    </DialogFooter>
                </Dialog>

                <Dialog
                    hidden={this.state && this.state.hideSavedDialog === null ? true : this.state && this.state.hideSavedDialog}
                    dialogContentProps={{
                        title: 'Switch saved',
                        subText: 'The Switch was saved successfully.'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Ok" />
                    </DialogFooter>
                </Dialog>

            </div>

        );
    }
}

export default SmartSwitchEditor;
