import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartToken} from "../model/smartToken";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";
import { ContentControlRevisions } from "../model/contentControlRevisions";

export class SmartTokenService {

    fetchSmartTokens() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartToken, null, null, true)
            .then((list: SmartToken) => {
                resolve(list);
            }).catch(error => {            
                Log.error("SmartTokenService:fetchSmartTokens", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchSmartTokenRevisions(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartTokenRevisions, id, null, true)
            .then((list: ContentControlRevisions) => {
                resolve(list);
            }).catch(error => {            
                Log.error("SmartTokenService:fetchSmartTokenRevisions", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getSmartToken(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartToken, id, null, true).then((smartToken: SmartToken) => {
                resolve(smartToken);
            }).catch(error => {   
                Log.error("SmartTokenService:getSmartToken", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    deleteSmartToken(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.removeEntity(OpalEntity.SmartToken, id, null).then((success) => {
                resolve(success);
            }).catch(error => {  
                Log.error("SmartTokenService:deleteSmartToken", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    saveSmartToken(smartToken) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.SmartToken, smartToken, {
                templateId: smartToken.template_id
            })
            .then((smartToken: SmartToken) => {
                resolve(smartToken);
            }).catch(error => {   
                Log.error("SmartTokenService:saveSmartToken", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateSmartToken(id, smartToken) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.updateEntity(OpalEntity.SmartToken, id, smartToken)
            .then((smartToken: SmartToken) => {
                resolve(smartToken);
            }).catch(error => {   
                Log.error("SmartTokenService:saveSmartToken", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
