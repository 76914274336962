import {Model} from "@voxfp/opal_ui_common";
import {Validators} from "./validators";

export class SmartChartType extends Model<SmartChartType> {
    id: number;
    name: string;
    validators: Validators;

    constructor(id?: number, name?: string, validators?: Validators) {
        super(SmartChartType);
        this.id = id;
        this.name = name;
        this.validators = validators;
    }
}
