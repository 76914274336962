import {Log, Util} from "@voxfp/opal_ui_common";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";
import { ContentControlRevisions } from "../model/contentControlRevisions";
import { SmartRepeat } from "../model/smartRepeat";

export class SmartRepeatService {

    saveSmartRepeat(smartRepeat) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.SmartRepeat, smartRepeat)
            .then((smartRepeat) => {
                resolve(smartRepeat);
            }).catch(error => {
                Log.error("RepeatService:saveSmartRepeat", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    deleteSmartRepeat(smartRepeat) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.removeEntity(OpalEntity.SmartRepeat, smartRepeat, null)
            .then((success) => {
                resolve(success);
            }).catch(error => {
                Log.error("RepeatService:deleteSmartRepeat", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateSmartRepeat(id, smartRepeat) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.updateEntity(OpalEntity.SmartRepeat, id, smartRepeat)
            .then((smartRepeat: SmartRepeat) => {
                resolve(smartRepeat);
            }).catch(error => {            
                Log.error("SmartRepeatService:updateSmartRepeat", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchSmartRepeats() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartRepeat, null, null, true)
            .then((smartRepeats) => {
                resolve(smartRepeats);
            }).catch(error => {
                Log.error("RepeatService:fetchSmartRepeats", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchSmartRepeatRevisions(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartRepeatRevisions, id, null, true)
            .then((list: ContentControlRevisions) => {
                resolve(list);
            }).catch(error => {            
                Log.error("SmartRepeatService:fetchSmartRepeatRevisions", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchSmartRepeatTypes() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartRepeatType, null, null, true)
            .then((smartRepeatTypes) => {
                let smartRepeatTypeListOptions = Util.orderOptions(smartRepeatTypes, 'id', 'name');
                resolve(smartRepeatTypeListOptions);
            }).catch(error => {
                Log.error("RepeatService:fetchSmartRepeatTypes", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getSmartRepeat(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartRepeat, id, null, true)
            .then((smartRepeat) => {
                resolve(smartRepeat);
            }).catch(error => {
                Log.error("RepeatService:getSmartRepeat", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
