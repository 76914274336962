import {Log, Util} from "@voxfp/opal_ui_common";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";
import { ContentControlRevisions } from "../model/contentControlRevisions";
import { SmartSwitch } from "../model/smartSwitch";

export class SmartSwitchService {

    saveSmartSwitch(smartSwitch) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.SmartSwitch, smartSwitch)
            .then((smartSwitch) => {
                resolve(smartSwitch);
            }).catch(error => {
                Log.error("SwitchService:saveSmartSwitch", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    deleteSmartSwitch(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.removeEntity(OpalEntity.SmartSwitch, id, null)
            .then((success) => {
                resolve(success);
            }).catch(error => {            
                Log.error("SmartSwitchService:deleteSmartSwitch", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateSmartSwitch(id, smartSwitch) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.updateEntity(OpalEntity.SmartSwitch, id, smartSwitch)
            .then((smartSwitch: SmartSwitch) => {
                resolve(smartSwitch);
            }).catch(error => {            
                Log.error("SmartSwitchService:updateSmartSwitch", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchSmartSwitches() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartSwitch, null, null, true)
            .then((smartSwitches: any) => {
                resolve(smartSwitches);
            }).catch(error => {            
                Log.error("SmartSwitchService:fetchSmartSwitches", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchSmartSwitchRevisions(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartSwitchRevisions, id, null, true)
            .then((list: ContentControlRevisions) => {
                resolve(list);
            }).catch(error => {            
                Log.error("SmartSwitchService:fetchSmartSwitchRevisions", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getSmartSwitch(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartSwitch, id, null, true)
            .then((smartSwitch: any) => {
                resolve(smartSwitch);
            }).catch(error => {            
                Log.error("SmartSwitchService:getSmartSwitch", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
