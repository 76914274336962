import {Model} from "@voxfp/opal_ui_common";

export class SmartRepeatType extends Model<SmartRepeatType> {

    id?: number;
    name?: string;

    constructor(id?: number, name?: string) {
        super(SmartRepeatType);
        this.id = id;
        this.name = name;
    }
}
