import * as apis from "../services/http";
import {ApiArguments} from "../services/http";
import {Log} from "@voxfp/opal_ui_common";

class StateListener {
    targetObject: string;
    handler: Function;

    constructor(targetObject: string, handler: Function) {
        this.targetObject = targetObject;
        this.handler = handler;
    }
}

export enum OpalEntity {
    ContentControlRevisions,
    Document,
    DocumentSearch,
    Template,
    TemplateRevision,
    TemplateCurrentRevision,
    TemplateDocuments,
    TemplateFamily,
    TemplateFamilyTemplates,
    PlaybookComposableTemplates,
    TemplateSearch,
    SmartTemplate,
    SmartTemplateRevisions,
    SmartToken,
    SmartTokenRevisions,
    SmartCondition,
    SmartConditionRevisions,
    SmartSwitch,
    SmartSwitchRevisions,
    SmartRule,
    SmartRuleRevisions,
    SmartRepeat,
    SmartRepeatType,
    SmartRepeatRevisions,
    TokenType,
    ReviewState,
    Playbook,
    Playbooks,
    PlaybookContentControlRevisions,
    PlaybookComposableContentControlRevisionsByType,
    WorkflowEvent,
    SmartChart,
    SmartChartType,
    SmartChartRevisions
}

class StateObserver {
    listListeners: Array<StateListener> = [];
    entityListeners: Array<StateListener> = [];

    observeEntityList(entity: OpalEntity, handler: any) {
        this.listListeners.push({
            targetObject: OpalEntity[entity],
            handler: handler,
        });
    }

    observeEntity(entity: OpalEntity, id: number, handler: any) {
        this.entityListeners.push({
            targetObject: `${entity}_${id}`,
            handler: handler,
        });
    }

    notifyListChanges(entity: OpalEntity, value: any) {
        this.listListeners.forEach(listener => {
            if (listener.targetObject === OpalEntity[entity]) {
                listener.handler(value);
            }
        });
    }

    notifyEntityChanges(entity: OpalEntity, id: number, value: any) {
        this.entityListeners.forEach(listener => {
            if (listener.targetObject === `${entity}_${id}`) {
                listener.handler(value);
            }
        });
    }
}

class StateManager {

    private observer: StateObserver = new StateObserver();
    private state: Map<string, any>;

    constructor() {
        this.state = new Map<string, any>();
    }

    private getStateObject(objectName: string) {
        return this.state.get(objectName);
    }

     private setStateObject(objectName: string, value: any) {
        this.state.set(objectName, value);
    }

    private removeStateObject(objectName: string) {
        this.state.delete(objectName);
    }

    private updateStateObject(objectName: string, updatedObject: any) {
        let obj = this.getStateObject(objectName);
        Object.keys(updatedObject).forEach((key) => {
            obj[key] = updatedObject[key];
        });
        this.setStateObject(objectName, obj);
        return obj;
    }

    fetchEntity(entity: OpalEntity, id: number, apiArgs?: ApiArguments, forceNew: boolean = false): Promise<any> {
        let value = this.state.get(`${entity}_${id}`);
        if (value && !forceNew) {
            Log.debug('Entity from state cache: ', JSON.stringify(value));
            return new Promise<any>(resolve => resolve(value));
        } else {
            return new Promise<any>((resolve, reject) => {
                new (apis as any)[`${OpalEntity[entity]}Api`](apiArgs).get(id).then(result => {
                    if (result) {
                        Log.debug('Entity from API: ', JSON.stringify(result));
                        this.setStateObject(`${entity}_${id}`, result);
                    }
                    resolve(result);
                }).catch(reject);
            });
        }
    }

    fetchEntityList(entity: OpalEntity, apiArgs?: ApiArguments, filterBy?: any, forceNew: boolean = false): Promise<Array<any>> {
        let value = this.state.get(`${entity}_list`);
        if (value && !forceNew) {
            Log.debug('List from state cache: ', JSON.stringify(value));
            return new Promise<Array<any>> (resolve => resolve(value));
        } else {
            return new Promise<Array<any>> ((resolve, reject) => {
                new (apis as any)[`${OpalEntity[entity]}Api`](apiArgs)[filterBy ? 'listBy' : 'list'](filterBy).then(result => {
                    this.setStateObject(`${entity}_list`, result);
                    Log.debug('List from API: ', JSON.stringify(result));
                    resolve(result);
                }).catch(reject);
            });
        }
    }

    createEntity(entity: OpalEntity, newEntity: any, apiArgs?: ApiArguments): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            new (apis as any)[`${OpalEntity[entity]}Api`](apiArgs).post(newEntity).then(result => {
                let resultingObject = {id: result.id || (result.data && result.data.id), ...result};
                let obj = result.data ? {data: resultingObject} : resultingObject;
                Log.debug('Created Entity: ', JSON.stringify(obj));
                this.setStateObject(`${entity}_${result.id}`, obj);
                let list = this.state.get(`${entity}_list`);
                if (list) {
                   list.push(newEntity);
                   this.observer.notifyListChanges(entity, list);
                }
                resolve(obj);
            }).catch(reject);
        });
    }

    updateEntity(entity: OpalEntity, id: number, updatedEntity: any, apiArgs?: ApiArguments): Promise<any> {
        let updatedObject = this.updateStateObject(`${entity}_${id}`, updatedEntity);
        let list = this.state.get(`${entity}_list`);
        if (list) {
            let index = list.findIndex(obj => obj.id === id);
            if (index > -1) {
                Object.keys(updatedObject).forEach((key) => {
                    list[index][key] = updatedObject[key];
                });
                this.observer.notifyListChanges(entity, list);
            }
        }
        this.observer.notifyEntityChanges(entity, id, updatedObject);
        Log.debug('Updated Entity in cache (not on API yet, will do now): ', JSON.stringify(updatedObject));
        return new (apis as any)[`${OpalEntity[entity]}Api`](apiArgs).put(id, updatedObject);
    }

    removeEntity(entity: OpalEntity, id: number, apiArgs?: ApiArguments): Promise<any> {
        this.removeStateObject(`${entity}_${id}`);
        let list = this.state.get(`${entity}_list`);
        if (list) {
            let index = list.findIndex(obj => obj.id === id);
            list.splice(index, 1);
            this.observer.notifyListChanges(entity, list);
        }
        this.observer.notifyEntityChanges(entity, id, null);
        Log.debug('Removed Entity from cache (not on API yet, will do now): ', entity);
        return new (apis as any)[`${OpalEntity[entity]}Api`](apiArgs).del(id);
    }

    observeList = this.observer.observeEntityList.bind(this.observer);
    observeEntity = this.observer.observeEntity.bind(this.observer);
}

declare global {
    interface Window {
        OpalStateManager: any;
    }
}

if (typeof window !== 'undefined') {
    window.OpalStateManager = new StateManager();
}

export const OpalStateManager = typeof window !== 'undefined' ? window.OpalStateManager : new StateManager();

export default OpalStateManager;
