import {Log, Util} from "@voxfp/opal_ui_common";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";
import {SmartChart} from "../model/smartChart";
import {ContentControlRevisions} from "../model/contentControlRevisions";

export class SmartChartService {
    fetchSmartCharts() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartChart, null, null, true)
                .then((list: SmartChart) => {
                    resolve(list);
                }).catch(error => {
                    Log.error("SmartChartService:fetchSmartCharts", error);
                    reject(CommonUtils.FormatError(error));
                }).finally(() => {
                    Util.stopSpinning();
                });
        });
    }

    fetchSmartChartRevisions(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartChartRevisions, id, null, true)
                .then((list: ContentControlRevisions) => {
                    resolve(list);
                }).catch(error => {
                    Log.error("SmartChartService:fetchSmartChartRevisions", error);
                    reject(CommonUtils.FormatError(error));
                }).finally(() => {
                    Util.stopSpinning();
                });
        });
    }

    getSmartChart(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartChart, id, null, true).then((smartChart: SmartChart) => {
                resolve(smartChart);
            }).catch(error => {
                Log.error("SmartChartService:getSmartChart", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    saveSmartChart(smartChart) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.SmartChart, smartChart)
                .then((smartChart: SmartChart) => {
                    resolve(smartChart);
                }).catch(error => {
                    Log.error("SmartChartService:saveChartToken", error);
                    reject(CommonUtils.FormatError(error));
                }).finally(() => {
                    Util.stopSpinning();
                });
        });
    }

    updateSmartChart(id, smartChart) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.updateEntity(OpalEntity.SmartChart, id, smartChart)
                .then((smartChart: SmartChart) => {
                    resolve(smartChart);
                }).catch(error => {
                    Log.error("SmartChartService:saveSmartChart", error);
                    reject(CommonUtils.FormatError(error));
                }).finally(() => {
                    Util.stopSpinning();
                });
        });
    }
}
