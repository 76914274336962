import {Model} from "@voxfp/opal_ui_common";
import {CurrentRevision} from "./currentRevision";
import {SmartChartType} from "./smartChartType";
import {Validators} from "./validators";
import {Playbook} from "./playbook";

export class SmartChart extends Model<SmartChart> {
    id: number;
    label: string;
    friendlyName: string;
    smartChartType: SmartChartType;
    playbook: Playbook;
    currentRevision: CurrentRevision;
    updatedAt: any;
    revision: CurrentRevision;
    validators: Validators;

    constructor(id?: number, label?: string, friendlyName?: string,
                smartChartType?: SmartChartType, playbook?: Playbook,
                currentRevision?: CurrentRevision, updatedAt?: any,
                validators?: Validators, revision?: CurrentRevision) {
        super(SmartChart);
        this.id = id;
        this.label = label;
        this.friendlyName = friendlyName;
        this.smartChartType = smartChartType;
        this.playbook = playbook;
        this.currentRevision = currentRevision;
        this.updatedAt = updatedAt;
        this.revision = revision;
        this.validators = validators;
    }
}
