import * as React from 'react';
import {Log, Util, Toast, ConditionBuilder} from "@voxfp/opal_ui_common";
import {TextField, Dropdown, Label, PrimaryButton, DefaultButton, MessageBar, MessageBarType, Dialog, DialogFooter, IDropdownOption} from 'office-ui-fabric-react';
import {PlaybookService} from '../../services/playbookService';
import {SmartConditionService} from '../../services/smartConditionService';
import { SmartCondition } from '../../model/smartCondition';
import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";

export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
    smartConditionId?: number;
    type?: any;
}

export interface AppState {
    playbooks: Array<any>;
    playbookId: number;
    playbookName: string;
    contentControls: Array<any>;
    query: any;
    name: any;
    description: any;
    templates: Array<any>;
    contentControlRevisionIfTrue: any;
    contentControlRevisionIfFalse: any;
    contentControlError: boolean;
    smartTemplatesError: boolean;
    saveError: boolean;
    errorMessage: string;
    hideCancelDialog: boolean;
    hideSavedDialog: boolean;
    formValid: boolean;
    status: string;
    loading: boolean;
    loadingPlaybooks: boolean;
    showPlaybookChangeConfirmation: any;
}

export class SmartConditionEditor extends React.Component<AppProps, AppState>  {

    playbookService: PlaybookService = new PlaybookService();
    smartConditionService: SmartConditionService = new SmartConditionService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    componentDidMount() {
        if (this.props.smartConditionId) {
            const id = this.props.smartConditionId;
            this.getSmartCondition(id);  
        }
        this.fetchPlaybooks();
    }

    fetchPlaybooks() {
        this.setState({
            loading: true
        });
        this.playbookService.fetchPlaybooks(true, true, null, null, null).then((playbooks: any) => {
            this.setState({ 
                playbooks: playbooks
            });
            this.setState(prevState => ({
                playbooks: [ {key: 0, text: "Select a Playbook", disabled: true}, ...prevState.playbooks]
            }));
        }).catch(error => {
            Log.error("Error fetching Playbooks", error);
            Util.showToast(new Toast('Error fetching Playbooks.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    getSmartCondition(id) {
        this.setState({
            loading: true
        });
        Log.debug("Fetching Condition", id);
        this.smartConditionService.getSmartCondition(id).then((smartCondition: SmartCondition) => {   
        
            this.setState({
                name: smartCondition.label,
                description: smartCondition.friendlyName,
                query: smartCondition.expression,
                playbookId: smartCondition.playbookId,
                playbookName: smartCondition.playbook,
                contentControlRevisionIfTrue: smartCondition.contentControlRevisionIdIfTrue,
                contentControlRevisionIfFalse: smartCondition.contentControlRevisionIdIfFalse !== undefined ? smartCondition.contentControlRevisionIdIfFalse : '',
                status: smartCondition.currentRevision["workflow_state"]
            });
            this.fetchPlaybooks();
            this.fetchContentControlRevisions(this.state.playbookId);
            this.fetchSmartTemplateRevisions(this.state.playbookId);
      
        }).catch(error => {
            Log.error("Error fetching Condition ID=" + id, error);            
            Util.showToast(new Toast('Error fetching Condition.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    fetchContentControlRevisions(playbookId) {
        this.setState({
            loading: true
        });
        this.playbookService.fetchComposableContentControlRevisionsByTypeFromPlaybook(playbookId, "SmartToken").then((contentControlRevisions: any) => {
            this.setState({ 
                contentControls: contentControlRevisions,
                contentControlError: false
            });
        }).catch(error => {
            Log.error('Error fetching Content Control Revisions from PlaybookId=' + playbookId, error);   
            this.setState({
                contentControls: [],
                contentControlError: true
            });
            Util.showToast(new Toast('Error fetching Content Control Revisions from Playbook.', MessageBarType.error));         
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    fetchSmartTemplateRevisions(playbookId) {
        this.setState({
            loading: true
        });
        this.playbookService.fetchComposableContentControlRevisionsByTypeFromPlaybook(playbookId, "SmartTemplate").then((smartTemplates: any) => {
            this.setState({ 
                templates: smartTemplates,
                smartTemplatesError: false
            });
            this.setState(prevState => ({
                templates: [ {key: 0, text: this.state.status === "Draft" || this.props.type === "create" ? "Select a Template" : "", disabled: true}, ...prevState.templates]
            }));                
        }).catch(error => {
            Log.error('Error fetching Sub-Templates from PlaybookId=' + playbookId, error);
            this.setState({
                templates: [],
                smartTemplatesError: true
            });
            Util.showToast(new Toast('Error fetching Sub-Templates from Playbook.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    handleNameInput(e) {
        this.setState({
            name: e.target.value
        });        
    }

    handleDescriptionInput(e) {
        this.setState({
            description: e.target.value
        });        
    }

    handlePlaybookChange(playbook) {
        if (this.props.type === "create") {
            this.setState({
                playbookId: playbook.key,
                playbookName: playbook.text
            });
            this.fetchContentControlRevisions(playbook.key);
            this.fetchSmartTemplateRevisions(playbook.key);
        }
        else {
            this.setState({
                showPlaybookChangeConfirmation: playbook
            });
        }
    }

    continuePlaybookChange(playbook) {
        this.setState({
            playbookId: playbook.key,
            playbookName: playbook.text,
            contentControlRevisionIfTrue: 0,
            contentControlRevisionIfFalse: 0,
            contentControls: null,
            showPlaybookChangeConfirmation: null,
            query: ''
        }, () => {
            this.fetchContentControlRevisions(playbook.key);
            this.fetchSmartTemplateRevisions(playbook.key);
        });
        
    }

    validateForm() {
        if (this.state.playbookId !== null && this.state.playbookId > 0 && this.state.contentControlRevisionIfTrue !== null && this.state.contentControlRevisionIfTrue > 0 && this.state.name.length > 0 && this.state.description.length > 0) {
            this.setState({
                formValid: true
            });
            return true;
        }
        else {
            this.setState({
                formValid: false
            });
            return false;
        }
    }

    handleTemplateTrueChange(e) {
        const id = e.key;    
        this.setState({
            contentControlRevisionIfTrue: id
        });
    }

    handleTemplateFalseChange(e) {
        const id = e.key;    
        this.setState({
            contentControlRevisionIfFalse: id
        });
    }

    handleQueryChange = (queryValue) => {
        this.setState({
            query: queryValue
        });
    }

    saveSmartCondition(revision?) {
        return new Promise((resolve, reject) => {
            let isValid = this.validateForm();
            if (isValid) {
                let smartCondition = {   
                    playbook_id: this.state.playbookId,         
                    name: this.state.name,
                    label: this.state.name,
                    description: this.state.description,
                    friendlyName: this.state.description,
                    expression: this.state.query,
                    content_control_revision_id_if_true: this.state.contentControlRevisionIfTrue,
                    content_control_revision_id_if_false: this.state.contentControlRevisionIfFalse || ''
                };
                Log.debug("Saving Condition", smartCondition);
                this.smartConditionService.saveSmartCondition(smartCondition).then((success) => {  
                    if (!revision) {                 
                        this.showSavedDialog();
                    }
                    resolve(success);
                }).catch(error => {
                    this.setState({
                        saveError: true,
                        errorMessage: error + ". "
                    });
                    Util.showToast(new Toast('Error saving Condition. ' + error, MessageBarType.error, 4000));
                    reject(error);
                });
            }
            else {
                Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
            }
        });
    }

    newRevision() {
        this.saveSmartCondition("revision").then((condition: any) => {
            window.location.assign("#/conditions//revisions/" + encodeURIComponent(condition.label) + "/" + condition.id + "//revisiondetails/" + condition.currentRevision.version + "/" + condition.id);
            this.getSmartCondition(condition.id);
        });
    }

    updateSmartCondition() {
        let isValid = this.validateForm();
        if (isValid) {
            let smartCondition = {   
                playbook_id: this.state.playbookId,         
                name: this.state.name,
                label: this.state.name,
                description: this.state.description,
                friendlyName: this.state.description,
                expression: this.state.query,
                content_control_revision_id_if_true: this.state.contentControlRevisionIfTrue,
                content_control_revision_id_if_false: this.state.contentControlRevisionIfFalse || ''
            };
            this.smartConditionService.updateSmartCondition(this.props.smartConditionId, smartCondition).then((_success) => {    
                this.showSavedDialog();
            }).catch(error => {
                this.setState({
                    saveError: true,
                    errorMessage: error + ". "
                });
                Util.showToast(new Toast('Error saving Condition. ' + error, MessageBarType.error, 4000));
            }); 
            
        }
        else {
            Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
        }
    }

    cancel() {
        this.setState({
            hideCancelDialog: false
        });
    }

    closeDialog() {
        this.setState({
            loadingPlaybooks: true,
            hideCancelDialog: true,
            showPlaybookChangeConfirmation: null,
        }, () => {
            this.setState({
                loadingPlaybooks: false
            });
        });
    }

    showSavedDialog() {
        this.setState({
            hideSavedDialog: false
        });
    }

    okContinue() {
        window.location.assign('#/conditions');
    }

    reset() {
        return {
            playbooks: (this.state && this.state.playbooks) || [],
            name: '',
            description: '',
            contentControlError: false,
            smartTemplatesError: false,
            playbookId: null,
            playbookName: '',
            contentControls: null,
            templates: [],
            contentControlRevisionIfTrue: 0,
            contentControlRevisionIfFalse: 0,
            query: '',
            hideCancelDialog: true,
            hideSavedDialog: true,
            saveError: false,
            errorMessage: "",
            formValid: null,
            loading: false,
            loadingPlaybooks: false,
            status: "",
            showPlaybookChangeConfirmation: null
        };
    }

    render() {
        Log.debug('Rendering Create/Edit Condition', this.state);
        return (
            
            <div className="mt1">
                <div>
                        <div className="ms-Grid-row">                    
                            <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                                <TextField 
                                    readOnly={(this.props.smartConditionId && this.state.status === "Draft") || this.props.type === "create" ? false : true}
                                    label="Name" 
                                    id="name" 
                                    className="mb05"  
                                    errorMessage={this.state && this.state.name.length === 0 && this.state.formValid === false ? "* required" : null} 
                                    value={this.state.name} 
                                    onChange ={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                        this.handleNameInput(event);
                                    }}
                                />
                                <TextField 
                                    readOnly={(this.props.smartConditionId && this.state.status === "Draft") || this.props.type === "create" ? false : true}
                                    label="Description" 
                                    id="description" 
                                    className="mb05"  
                                    errorMessage={this.state && this.state.description.length === 0 && this.state.formValid === false ? "* required" : null} 
                                    value={this.state.description} 
                                    onChange ={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                        this.handleDescriptionInput(event);
                                    }} 
                                />
                                {this.state && !this.state.loadingPlaybooks &&
                                    <div>
                                        <Label>Playbook</Label>
                                        <Dropdown responsiveMode={2}
                                            disabled={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? false : true}
                                            className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? "mb05" : "readOnlyStyle mb05"}
                                            placeholder=""
                                            notifyOnReselect={true}
                                            id='playbook'
                                            ariaLabel='Playbook'
                                            defaultSelectedKey={this.state && this.state.playbookId ? this.state.playbookId.toString() : 0}
                                            options={ this.state ? this.state.playbooks : [] }
                                            onChange={
                                                (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                                    this.handlePlaybookChange(item);
                                                }
                                            }
                                        />
                                    </div>
                                }
                                {this.state && this.state.contentControlError === true &&
                                    <div className="mb05">
                                        <MessageBar messageBarType={MessageBarType.error}>Selected playbook does not have any content controls.</MessageBar>
                                    </div>
                                }
                                {this.state && this.state.smartTemplatesError === true &&
                                    <div className="mb05">
                                        <MessageBar messageBarType={MessageBarType.error}>Selected playbook does not have any smart templates.</MessageBar>
                                    </div>
                                }
                            </div>
                        </div>
                        {this.state && this.state.contentControls &&
                        <div>
                            <Label>Condition</Label>
                            <div className={this.state && this.state.query === '' && this.state.formValid === false ? "required" : ""}>
                                <ConditionBuilder 
                                    conditions={this.state.contentControls}
                                    rules={this.state.query}
                                    onChange={this.handleQueryChange} 
                                    readOnly={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? false : true}
                                />
                            </div>
                            <br/>
                            <div className="ms-Grid-row">
                        
                            <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                                <Label>Sub-Template if true</Label>
                                <Dropdown responsiveMode={2}
                                    disabled={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? false : true}
                                    className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? "mb05" : "readOnlyStyle mb05"}
                                    placeholder=''
                                    notifyOnReselect={true}
                                    id='templateTrue'
                                    ariaLabel='Template to use if smartCondition statement is true'
                                    defaultSelectedKey={this.state && this.state.contentControlRevisionIfTrue ? this.state.contentControlRevisionIfTrue.toString() : 0}
                                    options={ this.state ? this.state.templates : [] }
                                    onChange={
                                        (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                            this.handleTemplateTrueChange(item);
                                        }
                                    }
                                    errorMessage={this.state && this.state.contentControlRevisionIfTrue === 0 && this.state.formValid === false ? "* required" : null} 
                                />
                                
                                <Label>Sub-Template if false (Optional)</Label>
                                <Dropdown responsiveMode={2}
                                    disabled={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? false : true}
                                    className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? "mb05" : "readOnlyStyle mb05"}
                                    placeholder=''
                                    notifyOnReselect={true}
                                    id='templateFalse'
                                    ariaLabel='Template to use if smartCondition statement is false'
                                    defaultSelectedKey={this.state && this.state.contentControlRevisionIfFalse ? this.state.contentControlRevisionIfFalse.toString() : 0}
                                    options={ this.state ? this.state.templates : [] }
                                    onChange={
                                        (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                            this.handleTemplateFalseChange(item);
                                        }
                                    }
                                />

                                {this.props.type === "create" &&
                                    <div className="buttonRow right">                                   
                                        <DefaultButton text="Cancel" className="mt1" onClick={this.cancel.bind(this)}></DefaultButton>                         
                                        <PrimaryButton iconProps={{ iconName : "Save" }} className="mt1" text="Save" onClick={() => {this.saveSmartCondition(); }}></PrimaryButton>
                                    </div>
                                }

                                {this.props.smartConditionId && this.state.status !== "Draft" &&
                                    <Can do="edit" on="control">
                                        <div className="buttonRow right">
                                            <PrimaryButton iconProps={{ iconName : "Tab" }} className="mt1" text="New Revision" onClick={() => {this.newRevision(); }}></PrimaryButton>
                                        </div>
                                    </Can>
                                }

                                {this.state && this.state.status === "Draft" && (this.props.type === "details" || this.props.type === "revisiondetails") &&
                                    <Can do="edit" on="control">
                                        <div className="buttonRow right">
                                            <DefaultButton text="Cancel" className="mt1" onClick={this.cancel.bind(this)}></DefaultButton>
                                            <PrimaryButton iconProps={{ iconName: "Save" }} className="mt1" text="Update" onClick={() => { this.updateSmartCondition(); }}></PrimaryButton>
                                        </div>
                                    </Can>                 
                                }
                                {this.state && this.state.saveError === true &&
                                    <div>
                                        <br/>
                                        <MessageBar messageBarType={MessageBarType.error}>Error saving SmartCondition. {this.state.errorMessage}Please check form and try again.</MessageBar>
                                    </div>
                                }

                            </div>
                        </div>
                        </div>
                        }
                        <br/>
                        
                    </div>
                
                <Dialog
                    hidden={this.state && this.state.hideCancelDialog === null ? true : this.state && this.state.hideCancelDialog}
                    onDismiss={this.closeDialog.bind(this)}
                    dialogContentProps={{
                        title: 'Cancel changes',
                        subText: 'This will remove all changes. Are you sure you want to cancel?'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                    >
                    <DialogFooter>
                        <DefaultButton onClick={this.closeDialog.bind(this)} text="No" />
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Yes" />
                    </DialogFooter>
                </Dialog>

                <Dialog
                    hidden={this.state && this.state.showPlaybookChangeConfirmation === null ? true : false}
                    onDismiss={this.closeDialog.bind(this)}
                    dialogContentProps={{
                        title: 'Change playbook',
                        subText: 'Changing the playbook will reset the condition. Are you sure you want to continue?'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                    >
                    <DialogFooter>
                        <DefaultButton onClick={this.closeDialog.bind(this)} text="No" />
                        <PrimaryButton onClick={() => this.continuePlaybookChange(this.state.showPlaybookChangeConfirmation)} text="Yes" />
                    </DialogFooter>
                </Dialog>

                <Dialog
                    hidden={this.state && this.state.hideSavedDialog === null ? true : this.state && this.state.hideSavedDialog}
                    dialogContentProps={{
                        title: 'Condition saved',
                        subText: 'The Condition was saved successfully.'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                    >
                    <DialogFooter>
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Ok" />
                    </DialogFooter>
                </Dialog>
    
            </div>
                
        );
    }
}

export default SmartConditionEditor;
