import {Model} from "@voxfp/opal_ui_common";
import {ContentControlRevisionCounts} from "./contentControlRevisionCounts";

export class Playbook extends Model<Playbook> {

    id?: number;
    name?: string;
    description?: string;
    contentControlRevisionCounts?: ContentControlRevisionCounts;

    constructor(id?: number, name?: string, description?: string, contentControlRevisionCounts?: ContentControlRevisionCounts) {
        super(Playbook);
        this.id = id;
        this.name = name;
        this.description = description;
        this.contentControlRevisionCounts = contentControlRevisionCounts;
    }
}
