import * as React from 'react';
import { Log, Util, Toast } from "@voxfp/opal_ui_common";
import { TextField, Dropdown, PrimaryButton, DefaultButton, Label, MessageBar, MessageBarType, Dialog, DialogFooter, IDropdownOption } from 'office-ui-fabric-react';
import { PlaybookService } from '../../services/playbookService';
import { SmartChartService } from '../../services/smartChartService';
import { SmartChartTypeService } from '../../services/smartChartTypeService';
import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";

export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
    chartId?: number;
    type?: string;
}

export interface AppState {
    playbooks: Array<any>;
    playbookId: number;
    playbookName: string;
    label: any;
    friendlyName: any;
    smartChartTypes: Array<any>;
    smartChartTypeName: string;
    smartChartTypeId: number;
    saveError: boolean;
    errorMessage: string;
    formValid: boolean;
    hideCancelDialog: boolean;
    hideSavedDialog: boolean;
    status: string;
    loading: boolean;
}

export class SmartChartEditor extends React.Component<AppProps, AppState>  {
    playbookService: PlaybookService = new PlaybookService();
    smartChartService: SmartChartService = new SmartChartService();
    smartChartTypeService: SmartChartTypeService = new SmartChartTypeService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    componentDidMount() {
        if (this.props.chartId) {
            const id = this.props.chartId;
            this.getSmartChart(id);
        }
        this.fetchPlaybooks();
        this.fetchSmartChartTypes();
    }

    fetchPlaybooks() {
        this.setState({
            loading: true
        });
        this.playbookService.fetchPlaybooks().then((playbooks: any) => {
            this.setState({
                playbooks: playbooks
            });
            this.setState(prevState => ({
                playbooks: [ {key: 0, text: "Select a Playbook", disabled: true}, ...prevState.playbooks]
            }));
        }).catch(error => {
            Log.error("Error fetching Playbooks", error);
            Util.showToast(new Toast('Error fetching Playbooks.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    getSmartChart(id) {
        this.setState({
            loading: true
        });
        this.smartChartService.getSmartChart(id).then((smartChart: any) => {
            this.setState({
                label: smartChart.label,
                friendlyName: smartChart.friendlyName,
                playbookId: smartChart.playbook.id,
                playbookName: smartChart.playbook.name,
                smartChartTypeName: smartChart.smartChartType.name,
                smartChartTypeId: smartChart.smartChartType.id,
                status: smartChart.revision.workflow_state
            });
            this.fetchPlaybooks();
            this.fetchSmartChartTypes();
        }).catch(error => {
            Log.error("Error fetching Chart ID=" + id, error);
            Util.showToast(new Toast('Error fetching Chart.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    fetchSmartChartTypes() {
        this.setState({
            loading: true
        });
        this.smartChartTypeService.fetchSmartChartTypes().then((smartChartTypes: any) => {
            let smartChartTypeList = Util.orderOptions(smartChartTypes, 'id', 'name');
            this.setState({
                smartChartTypes: smartChartTypeList
            });
            this.setState(prevState => ({
                smartChartTypes: [ {key: 0, text: "Select a Type", disabled: true}, ...prevState.smartChartTypes]
            }));
        }).catch(error => {
            Log.error('Error fetching Types', error);
            this.setState({
                smartChartTypes: []
            });
            Util.showToast(new Toast('Error fetching Types.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    handleNameInput(e) {
        this.setState({
            label: e.target.value
        });
    }

    handleDescriptionInput(e) {
        this.setState({
            friendlyName: e.target.value
        });
    }

    handleSmartChartTypeChange(smartChartType) {
        this.setState({
            smartChartTypeId: smartChartType.key,
            smartChartTypeName: smartChartType.text
        });
    }

    handlePlaybookChange(playbook) {
        this.setState({
            playbookId: playbook.key,
            playbookName: playbook.text
        });
    }

    validateForm() {
        if (this.state.playbookId !== null && this.state.playbookId > 0 && this.state.smartChartTypeId !== null && this.state.smartChartTypeId > 0 && this.state.label.length > 0 && this.state.friendlyName.length > 0) {
            this.setState({
                formValid: true
            });
            return true;
        }
        else {
            this.setState({
                formValid: false
            });
            return false;
        }
    }

    saveSmartChart(revision?) {
        return new Promise((resolve, reject) => {
            let isValid = this.validateForm();
            if (isValid) {
                let smartChart = {
                    playbook_id: this.state.playbookId,
                    label: this.state.label,
                    friendlyName: this.state.friendlyName,
                    smartChartTypeId: this.state.smartChartTypeId
                };

                this.smartChartService.saveSmartChart(smartChart).then((success) => {
                    if (!revision) {
                        this.showSavedDialog();
                    }
                    resolve(success);
                }).catch(error => {
                    this.setState({
                        saveError: true,
                        errorMessage: error + ". "
                    });
                    Util.showToast(new Toast('Error saving Chart.', MessageBarType.error, 4000));
                    reject(error);
                });

            }
            else {
                let error = "Please ensure all required fields are filled in.";
                Util.showToast(new Toast(error, MessageBarType.error));
                reject(error);
            }

        });
    }

    newRevision() {
        this.saveSmartChart("revision").then((chart: any) => {
            window.location.assign("#/charts//revisions/" + encodeURIComponent(chart.label) + "/" + chart.id + "//revisiondetails/" + chart.revision.version + "/" + chart.id);
            this.getSmartChart(chart.id);
        });
    }

    updateSmartChart() {
        let isValid = this.validateForm();
        if (isValid) {
            let smartChart = {
                playbook_id: this.state.playbookId,
                label: this.state.label,
                friendlyName: this.state.friendlyName,
                smartChartTypeId: this.state.smartChartTypeId
            };

            this.smartChartService.updateSmartChart(this.props.chartId, smartChart).then((_success) => {
                this.showSavedDialog();
            }).catch(error => {
                this.setState({
                    saveError: true,
                    errorMessage: error + ". "
                });
                Util.showToast(new Toast('Error saving Chart.', MessageBarType.error, 4000));
            });
        }
        else {
            Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
        }
    }

    cancel() {
        if (this.props.chartId) {
            this.okContinue();
        }
        else {
            this.setState({
                hideCancelDialog: false
            });
        }
    }

    closeDialog() {
        this.setState({
            hideCancelDialog: true
        });
    }

    showSavedDialog() {
        this.setState({
            hideSavedDialog: false
        });
    }

    okContinue() {
        window.location.assign('#/charts');
    }

    reset() {
        return {
            playbooks: (this.state && this.state.playbooks) || [],
            label: '',
            friendlyName: '',
            playbookId: 0,
            playbookName: '',
            smartChartTypes: (this.state && this.state.smartChartTypes) || [],
            smartChartTypeName: '',
            smartChartTypeId: 0,
            hideCancelDialog: true,
            hideSavedDialog: true,
            saveError: false,
            errorMessage: "",
            formValid: null,
            status: "",
            loading: false
        };
    }

    render() {
        Log.debug('Rendering Create Chart', this.state);
        return (
            <div className="mt1">
                {this.state && this.state.playbooks && this.state.smartChartTypes && this.state.loading === false &&
                <div>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                      <TextField
                        readOnly={this.props.chartId && this.state.status !== "Draft"}
                        label="Name"
                        id="name"
                        className="mb05"
                        errorMessage={this.state && this.state.label.length === 0 && this.state.formValid === false ? "* required" : null}
                        value={this.state.label}
                        onChange ={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                            this.handleNameInput(event);
                        }}
                      />
                      <TextField
                        readOnly={this.props.chartId && this.state.status !== "Draft"}
                        label="Description"
                        id="description"
                        className="mb05"
                        errorMessage={this.state && this.state.friendlyName.length === 0 && this.state.formValid === false ? "* required" : null}
                        value={this.state.friendlyName}
                        onChange ={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                            this.handleDescriptionInput(event);
                        }}
                      />
                      <Label>Playbook</Label>
                      <Dropdown responsiveMode={2}
                                disabled={!(this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create")}
                                className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? "mb05" : "readOnlyStyle mb05"}
                                placeholder=''
                                notifyOnReselect={true}
                                id='playbook'
                                ariaLabel='Playbook'
                                defaultSelectedKey={this.state && this.state.playbookId ? this.state.playbookId.toString() : 0}
                                options={ this.state && this.state.playbooks ? this.state.playbooks : [] }
                                onChange={
                                    (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                        this.handlePlaybookChange(item);
                                    }
                                }
                                errorMessage={this.state && this.state.playbookId === 0 && this.state.formValid === false ? "* required" : null}
                      />
                      <Label>Type</Label>
                      <Dropdown responsiveMode={2}
                                disabled={!(this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create")}
                                className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? "mb05" : "readOnlyStyle mb05"}
                                placeholder=''
                                notifyOnReselect={true}
                                id='smartChartType'
                                ariaLabel='SmartChartType'
                                defaultSelectedKey={this.state && this.state.smartChartTypeId ? this.state.smartChartTypeId : 0}
                                options={ this.state && this.state.smartChartTypes ? this.state.smartChartTypes : [] }
                                onChange={
                                    (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                        this.handleSmartChartTypeChange(item);
                                    }
                                }
                                errorMessage={this.state && this.state.smartChartTypeId === 0 && this.state.formValid === false ? "* required" : null}
                      />
                    </div>
                  </div>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                        {this.props.type === "create" &&
                        <div className="buttonRow right">
                          <DefaultButton text="Cancel" className="mt1" onClick={this.cancel.bind(this)}></DefaultButton>
                          <PrimaryButton iconProps={{ iconName : "Save" }} className="mt1" text="Save" onClick={() => {this.saveSmartChart(); }}></PrimaryButton>
                        </div>
                        }
                        {this.props.chartId && this.state.status !== "Draft" &&
                        <Can do="edit" on="control">
                          <div className="buttonRow right">
                            <PrimaryButton iconProps={{ iconName : "Tab" }} className="mt1" text="New Revision" onClick={() => {this.newRevision(); }}></PrimaryButton>
                          </div>
                        </Can>
                        }

                        {this.state && this.state.status === "Draft" && (this.props.type === "details" || this.props.type === "revisiondetails") &&
                        <Can do="edit" on="control">
                          <div className="buttonRow right">
                            <DefaultButton text="Cancel" className="mt1" onClick={this.cancel.bind(this)}></DefaultButton>
                            <PrimaryButton iconProps={{ iconName: "Save" }} className="mt1" text="Update" onClick={() => { this.updateSmartChart(); }}></PrimaryButton>
                          </div>
                        </Can>
                        }
                        {this.state && this.state.saveError === true &&
                        <div>
                          <br/>
                          <MessageBar messageBarType={MessageBarType.error}>Error saving Chart. {this.state.errorMessage}Please check form and try again.</MessageBar>
                        </div>
                        }
                    </div>
                  </div>
                  <br/>
                </div>
                }

                <Dialog
                    hidden={this.state && this.state.hideCancelDialog === null ? true : this.state && this.state.hideCancelDialog}
                    onDismiss={this.closeDialog.bind(this)}
                    dialogContentProps={{
                        title: 'Cancel changes',
                        subText: 'This will remove all changes. Are you sure you want to cancel?'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <DialogFooter>
                        <DefaultButton onClick={this.closeDialog.bind(this)} text="No" />
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Yes" />
                    </DialogFooter>
                </Dialog>

                <Dialog
                    hidden={this.state && this.state.hideSavedDialog === null ? true : this.state && this.state.hideSavedDialog}
                    dialogContentProps={{
                        title: 'Chart saved',
                        subText: 'The Chart was saved successfully.'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Ok" />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }
}

export default SmartChartEditor;
