import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartChartType} from "../model/smartChartType";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class SmartChartTypeService {
    fetchSmartChartTypes() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartChartType, null, null, true)
                .then((smartChartTypes: SmartChartType) => {
                    resolve(smartChartTypes);
                }).catch(error => {
                    Log.error("SmartChartTypeService:fetchSmartChartTypes", error);
                    reject(CommonUtils.FormatError(error));
                }).finally(() => {
                    Util.stopSpinning();
                });
        });
    }
}
