import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartTemplate} from "../model/smartTemplate";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";
import { ContentControlRevisions } from "../model/contentControlRevisions";

export class SmartTemplateService {

    fetchSmartTemplates() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartTemplate, null, null, true)
            .then((list: SmartTemplate) => {
                resolve(list);
            }).catch(error => {            
                Log.error("SmartTemplateService:fetchSmartTemplates", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchSmartTemplateRevisions(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartTemplateRevisions, id, null, true)
            .then((list: ContentControlRevisions) => {
                resolve(list);
            }).catch(error => {            
                Log.error("SmartTemplateService:fetchSmartTemplateRevisions", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getSmartTemplate(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartTemplate, id, null, true)
            .then((smartTemplate: SmartTemplate) => {
                resolve(smartTemplate);
            }).catch(error => {            
                Log.error("SmartTemplateService:getSmartTemplate", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    deleteSmartTemplate(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.removeEntity(OpalEntity.SmartTemplate, id, null)
            .then((success) => {
                resolve(success);
            }).catch(error => {            
                Log.error("SmartTemplateService:deleteSmartTemplate", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    saveSmartTemplate(smartTemplate) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.SmartTemplate, smartTemplate, {
                templateId: smartTemplate.template_id
            })
            .then((smartTemplate: SmartTemplate) => {
                resolve(smartTemplate);
            }).catch(error => {    
                Log.error("SmartTemplateService:saveSmartTemplate", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateSmartTemplate(id, smartTemplate) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.updateEntity(OpalEntity.SmartTemplate, id, smartTemplate)
            .then((smartTemplate: SmartTemplate) => {
                resolve(smartTemplate);
            }).catch(error => {   
                Log.error("SmartTemplateService:saveSmartTemplate", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
