import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './assets/styles/main.scss';
import {initializeIcons} from '@uifabric/icons';
import { Route, HashRouter, Switch, Redirect } from 'react-router-dom';
import { SmartConditions } from './components/smartConditions/smartConditions';
import { SmartSwitches } from './components/smartSwitches/smartSwitches';
import { SmartTemplates } from './components/smartTemplate/smartTemplates';
import { SmartTokens } from './components/smartToken/smartTokens';
import { SmartRules } from './components/smartRule/smartRules';
import { SmartRepeats } from './components/smartRepeat/smartRepeats';
import { SmartCharts } from './components/smartCharts/smartCharts';
import {createTheme, Customizations} from 'office-ui-fabric-react';
import {Header, ToastMessage, buildAbilities, AbilityContext} from '@voxfp/opal_ui_common';
import Menu from './components/menu/menu';
import Cookies from 'universal-cookie';

const myTheme = createTheme({
    palette: {
        themePrimary: '#45b6b7',
        themeLighterAlt: '#f6fcfc',
        themeLighter: '#dbf3f4',
        themeLight: '#bee9ea',
        themeTertiary: '#85d3d4',
        themeSecondary: '#57bec0',
        themeDarkAlt: '#3fa4a5',
        themeDark: '#358a8c',
        themeDarker: '#276667',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#c0c9c9',
        neutralSecondary: '#859292',
        neutralPrimaryAlt: '#536060',
        neutralPrimary: '#404b4b',
        neutralDark: '#303838',
        black: '#232929',
        white: '#ffffff',
    }
});


initializeIcons();

export interface AppProps {
    history?: any;
}

export interface AppState {
}

const ProtectedRoute = ({ component: Comp, loggedIn, path, title, permissions, ...rest }) => {
    return (
        <Route
            path={path}
            {...rest}
            render={(props) => {
                return loggedIn ? (
                    <Comp title={title} permissions={permissions} {...props} />
                ) : (
                        <Redirect
                            to={'/login'}
                        />
                    );
            }}
        />
    );
};

const cookies = new Cookies();

export class App extends React.Component<AppProps, AppState> {
    private authenticated = false;
    private ability;
    private user;

    onRouteChanged = () => {
        let authToken = cookies.get('authToken');
        let rules = cookies.get('permissions');
        let user = cookies.get('user');
        if (authToken) {
            this.authenticated = true;
            this.ability = buildAbilities(rules);
            this.user = user;
        }
        else {
            this.authenticated = false;
        }
        return true;
    }

    render() {
        Customizations.applySettings({ theme: myTheme });

        return (
            this.onRouteChanged() &&
            <AbilityContext.Provider value={this.ability}>
                <HashRouter>
                    {this.authenticated &&
                        <Header history={this.props.history} user={this.user} />
                    }
                    {this.authenticated &&
                        <Menu />
                    }
                    <div className="opalPage withMenu">
                        <ToastMessage />
                        <div className="opalContent">
                            <Switch>    
                                <Route path='/login' component={() => {
                                    window.location.assign(window.ENV.REACT_APP_OPAL_UI_DASHBOARD_URL + '/#/login');
                                    return null;
                                }} />      
                                <ProtectedRoute exact path="/conditions" loggedIn={this.authenticated} component={SmartConditions} title="Conditions" permissions={this.ability} />
                                <ProtectedRoute exact path="/conditions//:type/:name" loggedIn={this.authenticated} component={SmartConditions} title="Conditions" permissions={this.ability} />
                                <ProtectedRoute exact path="/conditions//:type/:name/:id" loggedIn={this.authenticated} component={SmartConditions} title="Conditions" permissions={this.ability} />
                                <ProtectedRoute exact path="/conditions//:type/:parentname/:parentid//:revisiontype/:name/:id" loggedIn={this.authenticated} component={SmartConditions} title="Conditions" permissions={this.ability} />

                                <ProtectedRoute exact path="/switches" loggedIn={this.authenticated} component={SmartSwitches} title="Switches" permissions={this.ability} />
                                <ProtectedRoute exact path="/switches//:type/:name" loggedIn={this.authenticated} component={SmartSwitches} title="Switches" permissions={this.ability} />
                                <ProtectedRoute exact path="/switches//:type/:name/:id" loggedIn={this.authenticated} component={SmartSwitches} title="Switches" permissions={this.ability} />
                                <ProtectedRoute exact path="/switches//:type/:parentname/:parentid//:revisiontype/:name/:id" loggedIn={this.authenticated} component={SmartSwitches} title="Switches" permissions={this.ability} />

                                <ProtectedRoute exact path="/templates" loggedIn={this.authenticated} component={SmartTemplates} title="Sub-Templates" permissions={this.ability} />
                                <ProtectedRoute exact path="/templates//:type/:name/:id" loggedIn={this.authenticated} component={SmartTemplates} title="Sub-Templates" permissions={this.ability} />

                                <ProtectedRoute exact path="/tokens" loggedIn={this.authenticated} component={SmartTokens} title="Tokens" permissions={this.ability} />
                                <ProtectedRoute exact path="/tokens//:type/:name" loggedIn={this.authenticated} component={SmartTokens} title="Tokens" permissions={this.ability} />
                                <ProtectedRoute exact path="/tokens//:type/:name/:id" loggedIn={this.authenticated} component={SmartTokens} title="Tokens" permissions={this.ability} />
                                <ProtectedRoute path="/tokens//:type/:parentname/:parentid//:revisiontype/:name/:id" loggedIn={this.authenticated} component={SmartTokens} title="Token" permissions={this.ability} />
                                
                                <ProtectedRoute exact path="/rules" loggedIn={this.authenticated} component={SmartRules} title="Rules" permissions={this.ability} />
                                <ProtectedRoute exact path="/rules//:type/:name" loggedIn={this.authenticated} component={SmartRules} title="Rules" permissions={this.ability} />
                                <ProtectedRoute exact path="/rules//:type/:name/:id" loggedIn={this.authenticated} component={SmartRules} title="Rules" permissions={this.ability} />

                                <ProtectedRoute exact path="/repeats" loggedIn={this.authenticated} component={SmartRepeats} title="Repeats" permissions={this.ability} />
                                <ProtectedRoute exact path="/repeats//:type/:name" loggedIn={this.authenticated} component={SmartRepeats} title="Repeats" permissions={this.ability} />
                                <ProtectedRoute exact path="/repeats//:type/:name/:id" loggedIn={this.authenticated} component={SmartRepeats} title="Repeats" permissions={this.ability} />
                                <ProtectedRoute path="/repeats//:type/:parentname/:parentid//:revisiontype/:name/:id" loggedIn={this.authenticated} component={SmartRepeats} title="Repeats" permissions={this.ability} />

                                <ProtectedRoute exact path="/charts" loggedIn={this.authenticated} component={SmartCharts} title="Charts" permissions={this.ability} />
                                <ProtectedRoute exact path="/charts//:type/:name" loggedIn={this.authenticated} component={SmartCharts} title="Charts" permissions={this.ability} />
                                <ProtectedRoute exact path="/charts//:type/:name/:id" loggedIn={this.authenticated} component={SmartCharts} title="Charts" permissions={this.ability} />
                                <ProtectedRoute path="/charts//:type/:parentname/:parentid//:revisiontype/:name/:id" loggedIn={this.authenticated} component={SmartCharts} title="Charts" permissions={this.ability} />

                                <ProtectedRoute exact path="/chart_types" loggedIn={this.authenticated} component={SmartCharts} title="ChartTypes" permissions={this.ability} />
                                <Redirect from="/" to="/tokens" />
                            </Switch>
                        </div>
                    </div>
                </HashRouter>
            </AbilityContext.Provider>   
                
        );
    }
}

ReactDOM.render(<App />, document.getElementById('container'));
