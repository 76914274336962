import * as React from 'react';
import {withRouter} from 'react-router-dom';
import {Nav} from 'office-ui-fabric-react';

const Menu = withRouter(({ history }) => (
    <div className="opal-side-nav p1">
        <Nav
            onLinkClick={(event, element) => {
                if (event && element) {
                    event.preventDefault();
                    history.push(element.url);
                }
            }}
            selectedKey={history.location.pathname.split("/")[1]}
            groups={[
                {
                    links: [
                    {
                        key: 'tokens',
                        name: 'Tokens',
                        url: '/tokens',
                        icon: 'FileCode'
                    },
                    {
                        key: 'templates',
                        name: 'Sub-Templates',
                        url: '/templates',
                        icon: 'FormLibrary'
                    },
                    {
                        key: 'conditions',
                        name: 'Conditions',
                        url: '/conditions',
                        icon: 'Dom'
                    },
                    {
                        key: 'switches',
                        name: 'Switches',
                        url: '/switches',
                        icon: 'Switch'
                    },                    
                    {
                        key: 'repeats',
                        name: 'Repeats',
                        url: '/repeats',
                        icon: 'RepeatAll'
                    },
                    {
                        key: 'rules',
                        name: 'Rules',
                        url: '/rules',
                        icon: 'SetAction'
                    },
                    {
                        key: 'charts',
                        name: 'Charts',
                        url: '/charts',
                        icon: 'Chart'
                    },
                    
                ]
            }
            ]}
       />
    </div>
));

export default Menu;
