import * as React from 'react';
import { Log, Util, Toast } from "@voxfp/opal_ui_common";
import { TextField, Dropdown, PrimaryButton, DefaultButton, MessageBar, MessageBarType, Dialog, DialogFooter, IDropdownOption, Label } from 'office-ui-fabric-react';
import { PlaybookService } from '../../services/playbookService';
import { SmartRepeatService } from '../../services/smartRepeatService';
import { TemplateService } from '../../services/templateService';
import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";

export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
    repeatId?: number;
    type?: string;
}

export interface AppState {
    playbooks: Array<any>;
    playbookId: number;
    playbookName: string;
    name: string;
    description: string;
    templates: Array<any>;
    templateName: string;
    templateId: number;
    smartRepeatTypes: Array<any>;
    smartRepeatTypeId: number;
    smartRepeatTypeName: string,
    smartRepeatsError: boolean;
    saveError: boolean;
    errorMessage: string;
    formValid: boolean;
    hideCancelDialog: boolean;
    hideSavedDialog: boolean;
    version: any;
    workflowState: string;
    status: string;
    loading: boolean;
}

export class SmartRepeatEditor extends React.Component<AppProps, AppState>  {

    playbookService: PlaybookService = new PlaybookService();
    smartRepeatService: SmartRepeatService = new SmartRepeatService();
    templateService: TemplateService = new TemplateService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    componentDidMount() {
        this.fetchPlaybooks();
        this.fetchSmartRepeatTypes();
        if (this.props.repeatId) {
            const id = this.props.repeatId;
            this.getSmartRepeat(id);
        }
    }

    fetchPlaybooks() {
        this.playbookService.fetchPlaybooks().then((playbooks: any) => {
            this.setState({
                playbooks: playbooks
            });
            this.setState(prevState => ({
                playbooks: [{ key: 0, text: "Select a Playbook", disabled: true }, ...prevState.playbooks]
            }));
        }).catch(error => {
            Log.error("Error fetching Playbooks", error);
            Util.showToast(new Toast('Error fetching Playbooks.', MessageBarType.error));
        });
    }

    getSmartRepeat(id) {
        this.setState({
            loading: true
        });
        this.smartRepeatService.getSmartRepeat(id).then((smartRepeat: any) => {
            if (smartRepeat.template) {
                this.setState({
                    templateId: smartRepeat.template.id,
                    templateName: smartRepeat.template.name
                }, () => {
                    this.fetchTemplates(smartRepeat.playbookId);
                    this.fetchTemplate(smartRepeat.template.id);
                });
                this.fetchTemplates(smartRepeat.playbookId);
                this.fetchTemplate(smartRepeat.template.id);
            }
            this.setState({
                name: smartRepeat.label,
                description: smartRepeat.friendlyName,
                playbookName: smartRepeat.playbook,
                playbookId: smartRepeat.playbookId,
                smartRepeatTypeName: smartRepeat.smartRepeatType,
                smartRepeatTypeId: smartRepeat.smartRepeatTypeId,
                status: smartRepeat.currentRevision["workflow_state"]
            });
            this.fetchPlaybooks();
            this.fetchSmartRepeatTypes();
        }).catch(error => {
            Log.error("Error fetching Repeat ID=" + id, error);
            Util.showToast(new Toast('Error fetching Repeat.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    fetchSmartRepeatTypes() {
        this.smartRepeatService.fetchSmartRepeatTypes().then((smartRepeatTypes: any) => {
            this.setState({
                smartRepeatTypes: smartRepeatTypes,
                smartRepeatsError: false
            });
            this.setState(prevState => ({
                smartRepeatTypes: [{ key: 0, text: "Select a Repeat Type", disabled: true }, ...prevState.smartRepeatTypes]
            }));
        }).catch(error => {
            Log.error('Error fetching Repeat Types', error);
            this.setState({
                smartRepeatTypes: [],
                smartRepeatsError: true
            });
            Util.showToast(new Toast('Error fetching Repeat Types.', MessageBarType.error));
        });
    }


    fetchTemplates(playbookId) {
        this.setState({
            loading: true
        });
        Log.debug("Fetching Templates from Playbook", playbookId);
        this.playbookService.fetchComposableTemplatesFromPlaybook(playbookId).then((templates: any) => {
            this.setState({
                templates: templates,
                smartRepeatsError: false
            });
            this.setState(prevState => ({
                templates: [{ key: 0, text: "Select a Template", disabled: true }, ...prevState.templates]
            }));
        }).catch(error => {
            Log.error('Error fetching Templates from PlaybookId=' + playbookId, error);
            this.setState({
                templates: [],
                smartRepeatsError: true
            });
            Util.showToast(new Toast('Error fetching Templates from Playbook.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    fetchTemplate(id) {
        this.templateService.getTemplate(id).then((template: any) => {
            this.setState({
                version: template.version,
                workflowState: template.currentRevision["workflow_state"],
            });
        }).catch(error => {
            Log.error('Error fetching Template with ID=' + id, error);
            this.setState({
                version: 0,
                workflowState: '',
                smartRepeatsError: true
            });
            Util.showToast(new Toast('Error fetching Template.', MessageBarType.error));
        });
    }

    fetchPlayBook() {
        this.setState({
            loading: true
        });
        this.playbookService.fetchPlaybooks().then((playbooks: any) => {
            playbooks.forEach((item) => {
                if (Number(item.id) === this.state.playbookId) {
                    this.setState({
                        playbookName: item.name
                    });
                }
            });
        }).catch(error => {
            Log.error('Error fetching Playbook', error);
            Util.showToast(new Toast('Error fetching Playbook.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    handleNameInput(e) {
        this.setState({
            name: e.target.value
        });
    }

    handleDescriptionInput(e) {
        this.setState({
            description: e.target.value
        });
    }

    handleSmartRepeatTypeChange(e) {
        const id = e.key;
        const name = e.text;
        if (name === "Table") {
            this.setState({
                templateId: null
            });
        }
        this.setState({
            smartRepeatTypeId: id,
            smartRepeatTypeName: name
        });
    }

    handleTemplateChange(e) {
        const id = e.key;
        this.fetchTemplate(id);
        this.setState({
            templateId: id
        });
    }

    handlePlaybookChange(playbook) {
        this.setState({
            playbookId: playbook.key,
            playbookName: playbook.text,
            templateId: 0,
            templateName: "",
            workflowState: "",
            version: ""
        });
        this.fetchTemplates(playbook.key);
    }

    validateForm() {
        if (this.state.smartRepeatTypeName === "Template"
            && this.state.playbookId !== null
            && this.state.templateId !== null
            && this.state.templateId > 0
            && this.state.playbookId > 0
            && this.state.name.length > 0
            && this.state.description.length > 0
            && this.state.smartRepeatTypeId > 0) {
            this.setState({
                formValid: true
            });
            return true;
        }
        else if (this.state.smartRepeatTypeName === "Table"
            && this.state.playbookId > 0
            && this.state.name.length > 0
            && this.state.description.length > 0
            && this.state.smartRepeatTypeId > 0) {
            this.setState({
                formValid: true
            });
            return true;
        }
        else {
            this.setState({
                formValid: false
            });
            return false;
        }
    }

    saveSmartRepeat(revision?) {
        return new Promise((resolve, reject) => {
            let isValid = this.validateForm();
            if (isValid) {
                let smartRepeat = {
                    playbookId: this.state.playbookId,
                    label: this.state.name,
                    friendlyName: this.state.description,
                    smartRepeatTypeId: this.state.smartRepeatTypeId,
                    templateId: this.state.templateId
                };

                Log.debug("Saving Repeat", smartRepeat);
                this.smartRepeatService.saveSmartRepeat(smartRepeat).then((success) => {
                    if (!revision) {
                        this.showSavedDialog();
                    }
                    resolve(success);
                }).catch(error => {
                    this.setState({
                        saveError: true,
                        errorMessage: error + ". "
                    });
                    Util.showToast(new Toast('Error saving Repeat. ' + error, MessageBarType.error, 4000));
                    reject(error);
                });

            }
            else {
                Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
                reject();
            }
        });
    }

    newRevision() {
        this.saveSmartRepeat("revision").then((item: any) => {
            window.location.assign("#/repeats//revisions/" + encodeURIComponent(item.label) + "/" + item.id + "//revisiondetails/" + item.currentRevision.version + "/" + item.id);
            this.getSmartRepeat(item.id);
        });
    }

    updateSmartRepeat() {
        let isValid = this.validateForm();
        if (isValid) {
            let smartRepeat = {
                playbookId: this.state.playbookId,
                label: this.state.name,
                friendlyName: this.state.description,
                smartRepeatTypeId: this.state.smartRepeatTypeId,
                templateRevisionId: this.state.templateId
            };
            this.smartRepeatService.updateSmartRepeat(this.props.repeatId, smartRepeat).then((_success) => {
                this.showSavedDialog();
            }).catch(error => {
                this.setState({
                    saveError: true,
                    errorMessage: error + ". "
                });
                Util.showToast(new Toast('Error saving Repeat. ' + error, MessageBarType.error, 4000));
            });

        }
        else {
            Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
        }
    }

    cancel() {
        if (this.props.repeatId) {
            this.okContinue();
        }
        else {
            this.setState({
                hideCancelDialog: false
            });
        }
    }

    closeDialog() {
        this.setState({
            hideCancelDialog: true
        });
    }

    showSavedDialog() {
        this.setState({
            hideSavedDialog: false
        });
    }

    okContinue() {
        window.location.assign('#/repeats');
    }

    reset() {
        return {
            playbooks: (this.state && this.state.playbooks) || [],
            name: '',
            description: '',
            currentStep: 1,
            stepTwoDisabled: true,
            smartRepeatsError: false,
            playbookId: 0,
            playbookName: '',
            templates: [],
            templateName: '',
            templateId: 0,
            smartRepeatTypes: [],
            smartRepeatTypeId: 0,
            smartRepeatTypeName: '',
            hideCancelDialog: true,
            hideSavedDialog: true,
            saveError: false,
            errorMessage: "",
            formValid: null,
            version: 0,
            workflowState: "",
            status: '',
            loading: false
        };
    }

    render() {
        Log.debug('Rendering Create Repeat', this.state);

        return (

            <div className="mt1">
                {this.state && !this.state.loading &&
                    <div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                                <TextField
                                    readOnly={(this.props.repeatId && this.state.status === "Draft") || this.props.type === "create" ? false : true}
                                    label="Name"
                                    id="name"
                                    className="mb05"
                                    value={this.state.name}
                                    errorMessage={this.state && this.state.name.length === 0 && this.state.formValid === false ? "* required" : null}
                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                        this.handleNameInput(event);
                                    }}
                                />
                                <TextField
                                    readOnly={(this.props.repeatId && this.state.status === "Draft") || this.props.type === "create" ? false : true}
                                    label="Description"
                                    id="description"
                                    className="mb05"
                                    value={this.state.description}
                                    errorMessage={this.state && this.state.description.length === 0 && this.state.formValid === false ? "* required" : null}
                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                        this.handleDescriptionInput(event);
                                    }}
                                />
                                <Label>Playbook</Label>
                                <Dropdown responsiveMode={2}
                                    disabled={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? false : true}
                                    className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? "mb05" : "readOnlyStyle mb05"}
                                    placeholder=""
                                    notifyOnReselect={true}
                                    id='playbook'
                                    ariaLabel='Playbook'
                                    defaultSelectedKey={this.state.playbookId ? this.state.playbookId.toString() : 0}
                                    options={this.state ? this.state.playbooks : []}
                                    onChange={
                                        (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                            this.handlePlaybookChange(item);
                                        }
                                    }
                                    errorMessage={this.state && this.state.playbookId === 0 && this.state.formValid === false ? "* required" : null}
                                />
                                {this.state && this.state.smartRepeatsError === true &&
                                    <div className="mb05">
                                        <MessageBar messageBarType={MessageBarType.error}>Selected playbook does not have any smart templates.</MessageBar>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="ms-Grid-row">

                            <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">

                                {this.state && this.state.smartRepeatTypes.length > 0 && this.state.playbookId > 0 &&
                                    <div>
                                        <Label>Repeat Type</Label>
                                        <Dropdown responsiveMode={2}
                                            disabled={this.props.type === "create" ? false : true}
                                            className={this.props.type === "create" ? "mb05" : "readOnlyStyle mb05"}
                                            placeholder=""
                                            notifyOnReselect={true}
                                            id='smartRepeatType'
                                            ariaLabel='Repeat Type'
                                            defaultSelectedKey={this.state.smartRepeatTypeId ? this.state.smartRepeatTypeId.toString() : 0}
                                            options={this.state ? this.state.smartRepeatTypes : []}
                                            onChange={
                                                (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                                    this.handleSmartRepeatTypeChange(item);
                                                }
                                            }
                                            errorMessage={this.state && this.state.smartRepeatTypeId === 0 && this.state.formValid === false ? "* required" : null}
                                        />
                                    </div>
                                }

                                {this.state && this.state.templates && this.state.smartRepeatTypeName === "Template" && this.state.playbookId > 0 && 
                                    <div>
                                        <Label>Template</Label>
                                        <Dropdown responsiveMode={2}
                                            disabled={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? false : true}
                                            className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? "mb05" : "readOnlyStyle mb05"}
                                            placeholder=""
                                            notifyOnReselect={true}
                                            id='templateTrue'
                                            ariaLabel='Template'
                                            defaultSelectedKey={this.state && this.state.templateId > 0 ? this.state.templateId.toString() : 0}
                                            options={this.state ? this.state.templates : []}
                                            onChange={
                                                (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                                    this.handleTemplateChange(item);
                                                }
                                            }
                                            errorMessage={this.state && this.state.templateId === 0 && this.state.formValid === false ? "* required" : null}
                                        />
                                    </div>
                                }

                                {this.state && this.state.workflowState !== '' &&
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6">
                                            <strong>Version: </strong>{this.state.version}
                                        </div>
                                        <div className="ms-Grid-col ms-sm6 right">
                                            <strong> Workflow State: </strong>{this.state.workflowState}
                                        </div>
                                    </div>
                                }

                                {this.props.type === "create" &&
                                    <div className="buttonRow right">
                                        <DefaultButton text="Cancel" className="mt1" onClick={this.cancel.bind(this)}></DefaultButton>
                                        <PrimaryButton iconProps={{ iconName: "Save" }} className="mt1" text="Save" onClick={() => { this.saveSmartRepeat(); }}></PrimaryButton>
                                    </div>
                                }

                                {this.props.repeatId && this.state.status !== "Draft" &&
                                    <Can do="edit" on="control">
                                        <div className="buttonRow right">
                                            <PrimaryButton iconProps={{ iconName: "Tab" }} className="mt1" text="New Revision" onClick={() => { this.newRevision(); }}></PrimaryButton>
                                        </div>
                                    </Can>
                                }

                                {this.state && this.state.status === "Draft" && (this.props.type === "details" || this.props.type === "revisiondetails") &&
                                    <Can do="edit" on="control">
                                        <div className="buttonRow right">
                                            <DefaultButton text="Cancel" className="mt1" onClick={this.cancel.bind(this)}></DefaultButton>
                                            <PrimaryButton iconProps={{ iconName: "Save" }} className="mt1" text="Update" onClick={() => { this.updateSmartRepeat(); }}></PrimaryButton>
                                        </div>
                                    </Can>
                                }
                                {this.state && this.state.saveError === true &&
                                    <div>
                                        <br />
                                        <MessageBar messageBarType={MessageBarType.error}>Error saving Repeat. {this.state.errorMessage}Please check form and try again.</MessageBar>
                                    </div>
                                }

                            </div>
                        </div>

                        <br />

                    </div>
                }

                <Dialog
                    hidden={this.state && this.state.hideCancelDialog === null ? true : this.state && this.state.hideCancelDialog}
                    onDismiss={this.closeDialog.bind(this)}
                    dialogContentProps={{
                        title: 'Cancel changes',
                        subText: 'This will remove all changes. Are you sure you want to cancel?'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <DialogFooter>
                        <DefaultButton onClick={this.closeDialog.bind(this)} text="No" />
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Yes" />
                    </DialogFooter>
                </Dialog>

                <Dialog
                    hidden={this.state && this.state.hideSavedDialog === null ? true : this.state && this.state.hideSavedDialog}
                    dialogContentProps={{
                        title: 'Repeat saved',
                        subText: 'The Repeat was saved successfully.'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Ok" />
                    </DialogFooter>
                </Dialog>

            </div>

        );
    }
}

export default SmartRepeatEditor;
