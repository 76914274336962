import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartRule} from "../model/smartRule";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";
import { ContentControlRevisions } from "../model/contentControlRevisions";

export class SmartRuleService {

    fetchSmartRules() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartRule, null, null, true).then((smartRules: SmartRule) => {
                resolve(smartRules);
            }).catch(error => {            
                Log.error("SmartRuleService:fetchSmartRules", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });           
    }

    fetchSmartRuleRevisions(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartRuleRevisions, id, null, true)
            .then((list: ContentControlRevisions) => {
                resolve(list);
            }).catch(error => {            
                Log.error("SmartRuleService:fetchSmartRuleRevisions", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchTokenTypes() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.TokenType)
            .then((tokenType: any) => {
                let list = [];
                let items = tokenType.data;
                items.forEach((i) => {
                    let item = i.attributes;
                    item.id = i.id;
                    list.push(item);
                });
                Util.stopSpinning();
                resolve(items);
            }).catch(error => {         
                Log.error("SmartRuleService:fetchTokenTypes", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchTokenTypeAttributes(tokenTypeId) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.TokenType, tokenTypeId).then((data: any) => {
                let tokenType = data.data;
                let tokenTypeAttributeOptions = [];
                tokenType.attributes['possible-validators'].forEach((attribute) => {
                    let found = tokenTypeAttributeOptions.findIndex(item => item.key === attribute.name ) >= 0;
                    if (!found) {
                        tokenTypeAttributeOptions.push({
                            attributeId: attribute.id,
                            key: attribute.id + attribute.name,
                            text: attribute.name,
                            description: attribute.description,
                            value: '',
                            dataType: attribute["data-type"]
                        });
                    }
                });
                resolve(tokenTypeAttributeOptions);
            }).catch(error => {         
                Log.error("SmartRuleService:fetchTokenTypeAttributes", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    saveSmartRule(smartRule) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.SmartRule, smartRule)
            .then((smartRule: SmartRule) => {
                resolve(smartRule);
            }).catch(error => {         
                Log.error("SmartRuleService:saveSmartRule", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getSmartRule(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartRule, id)
            .then((smartRule: SmartRule) => {
                resolve(smartRule);
            }).catch(error => {         
                Log.error("SmartRuleService:getSmartRule", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
