import * as React from 'react';
import {MessageBar, MessageBarType, IContextualMenuItem, Link} from 'office-ui-fabric-react';
import {DetailsListUtils, Log, Util, Toast, OpalTable} from "@voxfp/opal_ui_common";
import {SmartTokenService} from '../../services/smartTokenService';
import {WorkflowEventService} from '../../services/workflowEventService';

export interface AppProps {
    tokenId?: number;
    name?: string;
    type: string;
    history: any;
    location?: any;
    permissions: any;
}

export interface AppState {
    listItems: Array<any>;
    columns: Array<any>;
    isDataLoaded: boolean;
    isError: boolean;
}

export class SmartTokenList extends React.Component<AppProps, AppState>  {

    smartTokenService: SmartTokenService = new SmartTokenService();
    workflowEventService: WorkflowEventService = new WorkflowEventService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    reset() {
        return ({
            listItems: [],
            columns: [],
            isDataLoaded: false,
            isError: false
        });
    }

    componentDidMount() {
        this.loadList();
    }

    componentDidUpdate(i) {
        let newPath = i.history.location.pathname;
        if (newPath !== i.location.pathname) {
            this.loadList();
        } 
    }

    loadList() {
        if (this.props.tokenId > 0) {
            this.loadTokenRevisionsList();
        }
        else {
            this.loadTokenList();
        }
    }

    loadTokenList() {
        this.fetchSmartTokens();
        this.setTokenColumns();
    }

    loadTokenRevisionsList() {
        this.setState({
            listItems: []
        });
        this.fetchSmartTokenRevisions(this.props.tokenId);
        this.setTokenRevisionsColumns();
    }

    fetchSmartTokens() {
        Log.debug("Fetching Tokens", this.state);
        this.smartTokenService.fetchSmartTokens().then((items: any) => {
            DetailsListUtils.sortItems(items, "updatedAt", true);
            this.setState({
                listItems: items,
                isDataLoaded: true,
                isError: false
            });         
        }).catch(error => {
            this.setState({
                isDataLoaded: true,
                isError: true
            });
            Util.showToast(new Toast('Error fetching Tokens. ' + error, MessageBarType.error));          
        });
    }

    fetchSmartTokenRevisions(id) {
        Log.debug("Fetching Token Revisions", this.state);
        this.smartTokenService.fetchSmartTokenRevisions(id).then((items: any) => {
            DetailsListUtils.sortItems(items, "updatedAt", true);
            this.setState({
                listItems: items,
                isDataLoaded: true,
                isError: false
            });        
        }).catch(error => {
            this.setState({
                isDataLoaded: true,
                isError: true
            });
            Util.showToast(new Toast('Error fetching Token Revisions. ' + error, MessageBarType.error));          
        });
    }

    deleteItem(item) {
        this.smartTokenService.deleteSmartToken(item.details.id).then((_success) => {
            Util.showToast(new Toast('Token deleted.', MessageBarType.success));
            this.loadTokenRevisionsList();             
        }).catch(error => {
            Log.error("Error deleting Token", error);
            Util.showToast(new Toast('Error deleting Token. ' + error, MessageBarType.error, 4000));
        });
    }

    setTokenColumns() {
        const createAbility = this.props.permissions.can("create", "control");
        const templateListColumns = [
            {
                field: "label",
                headerText: "Name",
                width: "15",
                itemType: "Link",
                customAttributes: {
                    urlTemplate: "#/tokens//details/{label}/{id}"
                }
            },
            {
                field: "playbookName",
                headerText: "Playbook",
                width: "15",
                filter: "Checkbox"
            },
            {
                field: "smartRule.attributes.name",
                headerText: "Rule",
                width: "15",
                filter: "Checkbox",    
            },
            {
                field: "currentRevision.version",
                headerText: "Version",
                width: "10",
                filter: "Checkbox"
            },
            {
                field: "currentRevision.workflow_state",
                headerText: "Status",
                filter: "Checkbox",
                width: "15",
            },
            {
                field: "updatedAt",
                headerText: "Last Edit",
                width: "15",
                type: "date",
                format: "yyyy-MM-dd"
            },
            {
                field: "options",
                headerText: "Options",
                width: "15",
                disableSorting: true,
                disableFiltering: true,
                itemType: "Dropdown",
                customAttributes: {
                    buttonText: "Options",
                    dropdownTemplate: [{
                        sectionProps: [{
                            title: 'View',
                            items: [
                                {
                                    text: 'Version History',
                                    onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                        this.handleVersionHistoryClick(i.item);
                                    }
                                }
                            ]
                        },
                        {
                            title: createAbility ? 'Manage' : '',
                            items: [
                                {
                                    onRender: (i: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
                                        return (
                                            createAbility &&
                                            <Link disabled={i.item.currentRevision["workflow_state"] === "Draft" ? true : false} onClick={() => {
                                                this.handleNewRevisionClick(i.item);
                                                dismissMenu();
                                            }} className="opal-ContextualMenu-link">
                                                <div className="opal-ContextualMenu-linkContent">
                                                    <span className={i.item.currentRevision["workflow_state"] === "Draft" ? "opal-ContextualMenu-itemText disabled" : "opal-ContextualMenu-itemText"}>
                                                        New Revision
                                                    </span>
                                                </div>
                                            </Link>
                                        );
                                    }
                                },
                            ]
                        }]
                    }]
                }  
            }
        ];
        this.setState({
            columns: templateListColumns
        });
    }

    setTokenRevisionsColumns() {
        const readAbility = this.props.permissions.can("read", "control");
        const templateListColumns = [
            {
                field: "version",
                headerText: "Version",
                width: "15",
                itemType: "Link",
                customAttributes: {
                    urlTemplate: "#/tokens//revisions/" + encodeURIComponent(this.props.name) + "/" + this.props.tokenId + "//revisiondetails/{version}/{details.id}",
                    linkTextTemplate: "Version {version}"
                }
            },

            {
                field: "author",
                headerText: "Author",
                width: "20",
                filter: "Checkbox"
            },
            {
                field: "updatedAt",
                headerText: "Last Edit",
                width: "20",
                type: "date",
                format: "yyyy-MM-dd"
            },
            {
                field: "templatesCount",
                headerText: "Templates",
                width: "15",
            },
            {
                field: "workflowState",
                headerText: "State",
                width: "15",
                filter: "Checkbox"
            },
            {
                field: "options",
                headerText: readAbility ? "Options" : "",
                width: readAbility ? "15" : "0",
                disableSorting: true,
                disableFiltering: true,
                itemType: "Dropdown",
                customAttributes: {
                    buttonText: "Options",
                    dropdownTemplate: [{
                        sectionProps: [{
                            title: 'Actions',
                            items: [
                                {
                                    onRender: (i: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
                                        return (
                                            i.item.workflowEvents.map(
                                                (workflowItem, _key) => {
                                                return (
                                                        <Link onClick={() => {
                                                            this.handleWorkflowState(i.item.id, workflowItem.name);
                                                            dismissMenu();
                                                        }} className="opal-ContextualMenu-link">
                                                            <div className="opal-ContextualMenu-linkContent">
                                                                <span className="opal-ContextualMenu-itemText">
                                                                    {workflowItem.description}
                                                                </span>
                                                            </div>
                                                        </Link>
                                                    );
                                                }
                                            )
                                        );
                                    }
                                }
                            ]
                        }]
                    }]
                }   
            }
        ];
        this.setState({
            columns: templateListColumns
        });
    }

    handleVersionHistoryClick(item) {
        let name = encodeURIComponent(item.label);
        this.props.history.push("/tokens//revisions/" + name + "/" + item.id);
    }

    getSmartToken(id) {
        return new Promise((resolve, reject) => {
            this.smartTokenService.getSmartToken(id).then((smartToken: any) => {
                resolve(smartToken);
            }).catch(error => {
                Log.error("Error fetching Token ID=" + id, error);
                Util.showToast(new Toast('Error fetching Token.', MessageBarType.error));
                reject(error);
            });
        });
    }

    createSmartTokenRevision(token) {
        return new Promise((resolve, reject) => {
                let smartToken = {   
                    playbook_id: token.playbookId,         
                    label: token.label,
                    friendlyName: token.friendlyName,
                    smartRuleId: token.smartRule.id
                };
                this.smartTokenService.saveSmartToken(smartToken).then((success) => {  
                    resolve(success);
                }).catch(error => {
                    Util.showToast(new Toast('Error creating a revision. ' + error, MessageBarType.error, 4000));
                    reject(error);
                });
        });
    }

    handleNewRevisionClick(item) {
        this.getSmartToken(item.id).then((smartToken) => {
            this.createSmartTokenRevision(smartToken).then((token: any) => {
                window.location.assign("#/tokens//revisions/" + encodeURIComponent(token.label) + "/" + token.id + "//revisiondetails/" + token.revision.version + "/" + token.id);
            });
        });
    }

    handleWorkflowState(id, workflowEvent) {
        this.setItemState(id, workflowEvent);
    }

    setItemState(id, state) {
        this.workflowEventService.setWorkflowEvent(id, state).then((_data) => {
            Util.showToast(new Toast('Token workflow set to ' + state + '.', MessageBarType.success));
            this.loadList();
        })
        .catch((error) => {
            Util.showToast(new Toast('Error setting workflow. ' + error, MessageBarType.error));
        });
    }

    render() {

        Log.debug('Rendering Tokens List', this.state);

        return (
            <div className="mt1">
                {this.state && this.state.listItems.length > 0 && this.state.columns !== [] &&
                    <OpalTable
                        listData={this.state && this.state.listItems}
                        listColumns={this.state && this.state.columns}
                        allowPaging={true}
                        pageSettings={{
                            pageCount: 5,
                            pageSizes: true,
                            pageSize: 10
                        }}
                    />
                }
                {((this.state && this.state.isError) || (this.state && this.state.listItems.length === 0)) && this.state.isDataLoaded &&
                    <MessageBar>There are no items to show.</MessageBar>
                }
            </div>
        );
    }
}

export default SmartTokenList;
