import {Log, Util} from "@voxfp/opal_ui_common";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import { SmartCondition } from "../model/smartCondition";
import {CommonUtils} from "../utils/commonUtils";
import { ContentControlRevisions } from "../model/contentControlRevisions";

export class SmartConditionService {

    saveSmartCondition(smartCondition): Promise<SmartCondition> {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.SmartCondition, smartCondition)
            .then((smartCondition) => {
                resolve(smartCondition);
            }).catch(error => {
                Log.error("SmartCondtionService:saveSmartCondition", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchSmartConditionRevisions(id): Promise<ContentControlRevisions> {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartConditionRevisions, id, null, true)
            .then((list: ContentControlRevisions) => {
                resolve(list);
            }).catch(error => {            
                Log.error("SmartConditionService:fetchSmartConditionRevisions", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateSmartCondition(id, smartCondition) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.updateEntity(OpalEntity.SmartCondition, id, smartCondition)
            .then((smartCondition: SmartCondition) => {
                resolve(smartCondition);
            }).catch(error => {            
                Log.error("SmartConditionService:updateSmartCondition", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchSmartConditions(): Promise<SmartCondition> {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.SmartCondition, null, null, true)
            .then((smartConditions: SmartCondition) => {
                resolve(smartConditions);
            }).catch(error => {            
                Log.error("SmartConditionService:fetchSmartConditions", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getSmartCondition(id): Promise<SmartCondition> {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.SmartCondition, id, null, true)
            .then((smartCondition: SmartCondition) => {
                resolve(smartCondition);
            }).catch(error => {            
                Log.error("SmartConditionService:getSmartCondition", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    deleteSmartCondition(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.removeEntity(OpalEntity.SmartCondition, id, null)
            .then((success) => {
                resolve(success);
            }).catch(error => {            
                Log.error("SmartConditionService:deleteSmartCondition", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
