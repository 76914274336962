import * as React from 'react';
import {MessageBar, MessageBarType, IContextualMenuItem, Link} from 'office-ui-fabric-react';
import {DetailsListUtils, Log, Util, Toast, OpalTable} from "@voxfp/opal_ui_common";
import {SmartConditionService} from '../../services/smartConditionService';
import {WorkflowEventService} from '../../services/workflowEventService';
import { SmartCondition } from '../../model/smartCondition';

export interface AppProps {
    conditionId?: number;
    name?: string;
    type: string;
    history: any;
    location?: any;
    permissions: any;
}

export interface AppState {
    listItems: Array<any>;
    columns: Array<any>;
    isDataLoaded: boolean;
    isError: boolean;
}

export class SmartConditionList extends React.Component<AppProps, AppState>  {

    smartConditionService: SmartConditionService = new SmartConditionService();
    workflowEventService: WorkflowEventService = new WorkflowEventService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    reset() {
        return ({
            listItems: [],
            columns: [],
            isDataLoaded: false,
            isError: false
        });
    }

    componentDidMount() {
        this.loadList();
    }

    componentDidUpdate(i) {
        let newPath = i.history.location.pathname;
        if (newPath !== i.location.pathname) {
            this.loadList();
        } 
    }

    loadList() {
        if (this.props.conditionId > 0) {
            this.loadConditionRevisionsList();
        }
        else {
            this.loadConditionList();
        }
    }

    loadConditionList() {
        this.fetchSmartConditions();
        this.setConditionColumns();
    }

    loadConditionRevisionsList() {
        this.setState({
            listItems: []
        });
        this.fetchSmartConditionRevisions(this.props.conditionId);
        this.setConditionRevisionsColumns();
    }

    fetchSmartConditions() {
        Log.debug("Fetching Conditions", this.state);
        this.smartConditionService.fetchSmartConditions().then((items: any) => {
            DetailsListUtils.sortItems(items, "updatedAt", true);
            this.setState({
                listItems: items,
                isDataLoaded: true,
                isError: false
            });         
        }).catch(error => {
            this.setState({
                isDataLoaded: true,
                isError: true
            });
            Util.showToast(new Toast('Error fetching Conditions. ' + error, MessageBarType.error));          
        });
    }

    fetchSmartConditionRevisions(id) {
        Log.debug("Fetching Condition Revisions", this.state);
        this.smartConditionService.fetchSmartConditionRevisions(id).then((items: any) => {
            DetailsListUtils.sortItems(items, "updatedAt", true);
            this.setState({
                listItems: items,
                isDataLoaded: true,
                isError: false
            });        
        }).catch(error => {
            this.setState({
                isDataLoaded: true,
                isError: true
            });
            Util.showToast(new Toast('Error fetching Condition Revisions. ' + error, MessageBarType.error));          
        });
    }

    deleteItem(item) {
        Log.debug("Deleting Condition", item);
        this.smartConditionService.deleteSmartCondition(item.details.id).then((_data) => {
            Util.showToast(new Toast('Condition deleted.', MessageBarType.success));
            this.loadConditionRevisionsList(); 
        }).catch(error => {
            Log.error("Error deleting Condition", error);
            Util.showToast(new Toast('Error deleting Condition. ' + error, MessageBarType.error, 4000));
        });
    }

    setConditionColumns() {
        const createAbility = this.props.permissions.can("create", "control");
        const conditionListColumns = [
            {
                field: "label",
                headerText: "Name",
                width: "15",
                itemType: "Link",
                customAttributes: {
                    urlTemplate: "#/conditions//details/{label}/{id}"
                }
            },
            {
                field: "friendlyName",
                headerText: "Friendly Name",
                width: "15",
            },
            {
                field: "playbook",
                headerText: "Playbook",
                width: "15",
                filter: "Checkbox"
            },
            {
                field: "currentRevision.version",
                headerText: "Version",
                width: "10",
                filter: "Checkbox"
            },
            {
                field: "currentRevision.workflow_state",
                headerText: "Status",
                width: "10",
                filter: "Checkbox"
            },
            {
                field: "updatedAt",
                headerText: "Last Edit",
                width: "15",
                type: "date",
                format: "yyyy-MM-dd"
            },
            {
                field: "options",
                headerText: "Options",
                width: "15",
                disableSorting: true,
                disableFiltering: true,
                itemType: "Dropdown",
                customAttributes: {
                    buttonText: "Options",
                    dropdownTemplate: [{
                        sectionProps: [{
                            title: 'View',
                            items: [
                                {
                                    text: 'Version History',
                                    onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                        this.handleVersionHistoryClick(i.item);
                                    }
                                }
                            ]
                        },
                        {
                            title: createAbility ? 'Manage' : '',
                            items: [
                                {
                                    onRender: (i: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
                                        return (
                                            createAbility &&
                                            <Link disabled={i.item.currentRevision["workflow_state"] === "Draft" ? true : false} onClick={() => {
                                                this.handleNewRevisionClick(i.item);
                                                dismissMenu();
                                            }} className="opal-ContextualMenu-link">
                                                <div className="opal-ContextualMenu-linkContent">
                                                    <span className={i.item.currentRevision["workflow_state"] === "Draft" ? "opal-ContextualMenu-itemText disabled" : "opal-ContextualMenu-itemText"}>
                                                        New Revision
                                                    </span>
                                                </div>
                                            </Link>
                                        );
                                    }
                                }
                            ]
                        }]
                    }]
                }  
            }
        ];
        this.setState({
            columns: conditionListColumns
        });
    }

    setConditionRevisionsColumns() {
        const readAbility = this.props.permissions.can("read", "control");
        const conditionListColumns = [
            {
                field: "version",
                headerText: "Version",
                width: "15",
                itemType: "Link",
                customAttributes: {
                    urlTemplate: "#/conditions//revisions/" + encodeURIComponent(this.props.name) + "/" + this.props.conditionId + "//revisiondetails/{version}/{details.id}",
                    linkTextTemplate: "Version {version}"
                }
            },

            {
                field: "author",
                headerText: "Author",
                width: "20",
                filter: "Checkbox"
            },
            {
                field: "updatedAt",
                headerText: "Last Edit",
                width: "20",
                type: "date",
                format: "yyyy-MM-dd"
            },
            {
                field: "templatesCount",
                headerText: "Conditions",
                width: "15",
            },
            {
                field: "workflowState",
                headerText: "State",
                width: "15",
                filter: "Checkbox"
            },
            {
                field: "options",
                headerText: readAbility ? "Options" : "",
                width: readAbility ? "15" : "0",
                disableSorting: true,
                disableFiltering: true,
                itemType: "Dropdown",
                customAttributes: {
                    buttonText: "Options",
                    dropdownTemplate: [{
                        sectionProps: [{
                            title: 'Actions',
                            items: [
                                {
                                    onRender: (i: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
                                        return (
                                            i.item.workflowEvents.map(
                                                (workflowItem, _key) => {
                                                return (
                                                        <Link onClick={() => {
                                                            this.handleWorkflowState(i.item.id, workflowItem.name);
                                                            dismissMenu();
                                                        }} className="opal-ContextualMenu-link">
                                                            <div className="opal-ContextualMenu-linkContent">
                                                                <span className="opal-ContextualMenu-itemText">
                                                                    {workflowItem.description}
                                                                </span>
                                                            </div>
                                                        </Link>
                                                    );
                                                }
                                            )
                                        );
                                    }
                                }
                            ]
                        }]
                    }]
                }    
            }
        ];
        this.setState({
            columns: conditionListColumns
        });
    }

    handleVersionHistoryClick(item) {
        let name = encodeURIComponent(item.label);
        this.props.history.push("/conditions//revisions/" + name + "/" + item.id);
    }

    handleNewRevisionClick(item) {
        this.getSmartCondition(item.id).then((smartCondition) => {
            this.createSmartConditionRevision(smartCondition).then((condition: SmartCondition) => {
                window.location.assign("#/conditions//revisions/" + encodeURIComponent(condition.label) + "/" + condition.id + "//revisiondetails/" + condition.currentRevision.version + "/" + condition.id);
            });
        });
    }

    getSmartCondition(id: SmartCondition): Promise<SmartCondition> {
        return new Promise((resolve, reject) => {
            this.smartConditionService.getSmartCondition(id).then((smartCondition: SmartCondition) => {
                resolve(smartCondition);
            }).catch(error => {
                Log.error("Error fetching Condition ID=" + id, error);
                Util.showToast(new Toast('Error fetching Condition. ' + error, MessageBarType.error));
                reject(error);
            });
        });
    }

    createSmartConditionRevision(condition: SmartCondition): Promise<SmartCondition> {
        return new Promise((resolve, reject) => {
            let smartCondition = {   
                playbook_id: condition.playbookId,         
                name: condition.label,
                description: condition.friendlyName,
                expression: condition.expression,
                content_control_revision_id_if_true: condition.contentControlRevisionIdIfTrue,
                content_control_revision_id_if_false: condition.contentControlRevisionIdIfFalse
            };
            this.smartConditionService.saveSmartCondition(smartCondition).then((success) => {  
                resolve(success);
            }).catch(error => {
                Util.showToast(new Toast('Error creating a revision. ' + error, MessageBarType.error, 4000));
                reject(error);
            });
        });
    }

    handleWorkflowState(id, workflowEvent) {
        this.setItemState(id, workflowEvent);
    }

    setItemState(id, state) {
        this.workflowEventService.setWorkflowEvent(id, state).then((_data) => {
            Util.showToast(new Toast('Condition workflow set to ' + state + '.', MessageBarType.success));
            this.loadList();
        })
        .catch((error) => {
            Util.showToast(new Toast('Error setting workflow. ' + error, MessageBarType.error));
        });
    }

    render() {

        Log.debug('Rendering Conditions List', this.state);

        return (
            <div className="mt1">
                {this.state && this.state.listItems.length > 0 && this.state.columns !== [] &&
                    <OpalTable
                        listData={this.state && this.state.listItems}
                        listColumns={this.state && this.state.columns}
                        allowPaging={true}
                        pageSettings={{
                            pageCount: 5,
                            pageSizes: true,
                            pageSize: 10
                        }}
                    />
                }
                {((this.state && this.state.isError) || (this.state && this.state.listItems.length === 0)) && this.state.isDataLoaded &&
                    <MessageBar>There are no items to show.</MessageBar>
                }
            </div>
        );
    }
}

export default SmartConditionList;




