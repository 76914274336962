import {Model} from "@voxfp/opal_ui_common";

export class TokenType extends Model<TokenType> {

    id: number;
    type: string;
    name: string;

    constructor(id?: number, type?: string, name?: string) {
        super(TokenType);
        this.id = id;
        this.type = type;
        this.name = name;
    }
}
