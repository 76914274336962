import {EntityApi, HttpApi} from "@voxfp/opal_ui_common";
import {Playbook} from '../model/playbook';
import {Template} from '../model/template';
import {SmartTemplate} from '../model/smartTemplate';
import {SmartCondition} from '../model/smartCondition';
import {SmartSwitch} from '../model/smartSwitch';
import {SmartToken} from '../model/smartToken';
import {SmartRule} from '../model/smartRule';
import {SmartRepeat} from '../model/smartRepeat';
import {SmartRepeatType} from '../model/smartRepeatType';
import {ContentControlRevisions} from "../model/contentControlRevisions";
import {TemplateRevision} from '../model/templateRevision';
import {TokenType} from '../model/tokenType';
import {SmartChartType} from "../model/smartChartType";
import {SmartChart} from "../model/smartChart";

export class ApiArguments {
    id?: number;
    templateId?: number;
    templatableId?: number;
    templateFamilyId?: number;
    scope?: string;
    type?: string;
    tokenId?: number;
    smartRuleId?: number;
    documentId?: number;
}

export class PlaybooksApi extends EntityApi<any> {
    constructor() {
        super({
            url: '/playbooks/',
            isJSON: false,
            dataIsQueryParams: true,
            modelClass: new Playbook()
        });
    }
}

export class TemplateApi extends EntityApi<Template> {
    constructor() {
        super({
            url: '/templates', 
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new Template()});
    }
}

export class TemplateRevisionApi extends EntityApi<TemplateRevision> {
    constructor() {
        super({
            url: '/template_revisions/', 
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new TemplateRevision()});
    }
}

export class TemplateCurrentRevisionApi extends EntityApi<TemplateRevision> {
    constructor(args) {
        super({
            url: '/templates/' + args + '/template_revisions/current_revision', 
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new TemplateRevision()});
    }
}

export class ContentControlRevisionsApi extends EntityApi<ContentControlRevisions> {
    constructor() {
        super({
            url: '/content_control_revisions', 
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new ContentControlRevisions()});
    }
}

export class PlaybookContentControlRevisionsApi extends EntityApi<ContentControlRevisions> {
    constructor(id) {
        super({
            url: '/playbooks/' + id + '/content_control_revisions', 
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new ContentControlRevisions()});
    }
}

export class PlaybookComposableTemplatesApi extends EntityApi<Template> {
    constructor(id) {
        super({
            url: '/playbooks/' + id + '/templates?compose=true', 
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new Template()});
    }
}

export class PlaybookComposableContentControlRevisionsByTypeApi extends EntityApi<ContentControlRevisions> {
    constructor(args) {
        super({
            url: '/playbooks/' + args.id + '/content_control_revisions?type=' + args.type + '&compose=true', 
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new ContentControlRevisions()});
    }
}

export class SmartConditionApi extends EntityApi<SmartCondition> {
    constructor() {
        super({
            url: '/smart_conditions', 
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartCondition()});
    }
}

export class SmartConditionRevisionsApi extends EntityApi<ContentControlRevisions> {
    constructor(args) {
        super({
            url: '/smart_conditions/' + args + '/content_control_revisions',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new ContentControlRevisions()});
    }
}

export class SmartSwitchApi extends EntityApi<SmartSwitch> {
    constructor() {
        super({
            url: '/smart_switches', 
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartSwitch()});
    }
}

export class SmartSwitchRevisionsApi extends EntityApi<ContentControlRevisions> {
    constructor(args) {
        super({
            url: '/smart_switches/' + args + '/content_control_revisions',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new ContentControlRevisions()});
    }
}

export class SmartTemplateApi extends EntityApi<SmartTemplate> {
    constructor() {
        super({
            url: '/smart_templates',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartTemplate()});
    }
}

export class SmartTemplateRevisionsApi extends EntityApi<ContentControlRevisions> {
    constructor(args) {
        super({
            url: '/smart_templates/' + args + '/content_control_revisions',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new ContentControlRevisions()});
    }
}

export class SmartTokenApi extends EntityApi<SmartToken> {
    constructor() {
        super({
            url: '/smart_tokens',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartToken()});
    }
}

export class SmartTokenRevisionsApi extends EntityApi<ContentControlRevisions> {
    constructor(args) {
        super({
            url: '/smart_tokens/' + args + '/content_control_revisions',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new ContentControlRevisions()});
    }
}

export class SmartRuleApi extends EntityApi<SmartRule> {
    constructor() {
        super({
            url: '/smart_rules',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartRule()});
    }
}

export class SmartRuleRevisionsApi extends EntityApi<ContentControlRevisions> {
    constructor(args) {
        super({
            url: '/smart_repeats/' + args + '/content_control_revisions',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new ContentControlRevisions()});
    }
}

export class TokenTypeApi extends EntityApi<TokenType> {
    constructor() {
        super({
            url: '/token_types', 
            isJSON: false, 
            dataIsQueryParams: true,
            modelClass: new TokenType()});
    }
}

export class SmartRepeatApi extends EntityApi<SmartRepeat> {
    constructor() {
        super({
            url: '/smart_repeats',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new SmartRepeat()});
    }
}

export class SmartRepeatRevisionsApi extends EntityApi<ContentControlRevisions> {
    constructor(args) {
        super({
            url: '/smart_repeats/' + args + '/content_control_revisions',
            isJSON: true, 
            dataIsQueryParams: false, 
            modelClass: new ContentControlRevisions()});
    }
}

export class SmartRepeatTypeApi extends EntityApi<SmartRepeatType> {
    constructor() {
        super({
            url: '/smart_repeat_types',
            isJSON: true, 
            dataIsQueryParams: false,
            modelClass: new SmartRepeatType()
        });
    }
}

export class WorkflowEventApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/content_control_revisions/' + args + '/events',
            isJSON: true, 
            dataIsQueryParams: false
        });
    }
}

export class LoginApi {
    public static login = new HttpApi().login;
    public static checkLogin = new HttpApi().checkLogin;
}

export class SmartChartTypeApi extends EntityApi<SmartChartType> {
    constructor() {
        super({
            url: '/smart_chart_types',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartChartType()
        });
    }
}

export class SmartChartApi extends EntityApi<SmartChart> {
    constructor() {
        super({
            url: '/smart_charts',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartChart()
        });
    }
}

export class SmartChartRevisionsApi extends EntityApi<ContentControlRevisions> {
    constructor(args) {
        super({
            url: '/smart_charts/' + args + '/content_control_revisions',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new ContentControlRevisions()});
    }
}
