import {Log, Util} from "@voxfp/opal_ui_common";
import {Template} from "../model/template";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";
import { TemplateRevision } from "../model/templateRevision";

export class TemplateService {


    fetchTemplates() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.Template)
            .then(async (templates: Template) => {
                let templateList = Util.orderOptions(templates, 'id', 'name');
                resolve(templateList);
            }).catch(error => {    
                Log.error("TemplateService:fetchTemplates", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getTemplate(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.Template, id, null, true)
            .then(async (template: Template) => {
                resolve(template);
            }).catch(error => {    
                Log.error("TemplateService:getTemplate", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getTemplateRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.TemplateRevision, id, null, true)
            .then(async (template: Template) => {
                resolve(template);
            }).catch(error => {    
                Log.error("TemplateService:getTemplateRevision", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getTemplateCurrentRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.TemplateCurrentRevision, id, null, true)
            .then(async (templateRevision: TemplateRevision) => {
                resolve(templateRevision);
            }).catch(error => {    
                Log.error("TemplateService:getTemplateCurrentRevision", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }
}
