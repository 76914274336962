import * as React from 'react';
import { Log, Util, Toast } from "@voxfp/opal_ui_common";
import { TextField, MessageBarType } from 'office-ui-fabric-react';
import { SmartTemplateService } from '../../services/smartTemplateService';

export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
    templateId?: number;
    type?: string;
}

export interface AppState {
    playbookName: string;
    name: string;
    description: string;
    templateName: string;
    version: number;
    workflowState: string;
    loading: boolean;
}

export class SmartTemplateEditor extends React.Component<AppProps, AppState>  {

    smartTemplateService: SmartTemplateService = new SmartTemplateService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    componentDidMount() {
        const id = this.props.templateId;
        this.getSmartTemplate(id);
    }

    getSmartTemplate(id) {
        this.setState({
            loading: true
        });
        this.smartTemplateService.getSmartTemplate(id).then((subTemplate: any) => {
            this.setState({
                name: subTemplate.label,
                description: subTemplate.friendlyName,
                playbookName: subTemplate.playbook,
                templateName: subTemplate.template.name,
                workflowState: subTemplate.currentRevision["workflow_state"],
                version: subTemplate.currentRevision["version"]
            }); 
        }).catch(error => {
            Log.error("Error fetching Sub-Template ID=" + id, error);
            Util.showToast(new Toast('Error fetching Sub-Template. ' + error, MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    reset() {
        return {
            name: '',
            description: '',
            playbookName: '',
            templateName: '',
            version: 0,
            workflowState: "",
            loading: false
        };
    }

    render() {
        Log.debug('Rendering Sub-Template', this.state);

        return (

            <div className="mt1">
                {this.state && !this.state.loading &&
                    <div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">

                                <TextField
                                    readOnly={true}
                                    label="Name"
                                    id="name"
                                    className="mb05"
                                    value={this.state.name}
                                />
                                <TextField
                                    readOnly={true}
                                    label="Description"
                                    id="description"
                                    className="mb05"
                                    value={this.state.description}
                                />
                                <TextField
                                    readOnly={true}
                                    label="Playbook"
                                    id="playbook"
                                    className="mb05"
                                    value={this.state.playbookName}
                                />
                                <TextField
                                    readOnly={true}
                                    label="Template"
                                    id="template"
                                    className="mb05"
                                    value={this.state.templateName}
                                />
                                
                                {this.state && this.state.workflowState !== '' &&
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6">
                                            <strong>Version: </strong>{this.state.version}
                                        </div>
                                        <div className="ms-Grid-col ms-sm6 right">
                                            <strong> Workflow State: </strong>{this.state.workflowState}
                                        </div>
                                    </div>
                                }
                                
                            </div>
                        </div>

                    </div>
                }

            </div>

        );
    }
}

export default SmartTemplateEditor;
