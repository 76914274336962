import {Model} from "@voxfp/opal_ui_common";
import {Validators} from "./validators";
import { CurrentRevision } from "./currentRevision";

export class SmartRule extends Model<SmartRule> {
    id: number;
    name: string;
    description: string;
    tokenTypeName: string;
    updatedAt: any;
    validators: Validators;
    currentRevision?: CurrentRevision;

    constructor(id?: number, name?: string, description?: string, tokenTypeName?: string, updatedAt?: any, validators?: Validators, currentRevision?: CurrentRevision) {
        super(SmartRule);
        this.id = id;
        this.name = name;
        this.description = description;
        this.tokenTypeName = tokenTypeName;
        this.updatedAt = updatedAt;
        this.validators = validators;
        this.currentRevision = currentRevision;
    }
}
