import {Model} from "@voxfp/opal_ui_common";

export class TemplateRevision extends Model<TemplateRevision> {
    updatedAt?: any;
    version?: string;
    template?: any;
    templateFamily?: any;
    workflowState?: string;

    constructor(updatedAt?: any, version?: string, template?: any, templateFamily?: any, workflowState?: string) {
        super(TemplateRevision);
        this.updatedAt = updatedAt;
        this.version = version;
        this.template = template;
        this.templateFamily = templateFamily;
        this.workflowState = workflowState;
    }
}
