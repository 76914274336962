import * as React from "react";
import { RouteComponentProps } from 'react-router';
import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";
import {PrimaryButton} from "office-ui-fabric-react";
import {OpalBreadcrumbBar} from "../opalBreadcrumbBar/breadcrumb";
import { Log } from "@voxfp/opal_ui_common";
import {SmartChartEditor} from "./edit";
import {SmartChartList} from "./list";

export interface AppProps {
    history: any;
    title: any;
    location?: any;
}

export interface AppState {
    viewType: string;
    name: string;
    chartId: number;
}

export class SmartCharts extends React.Component<AppProps & RouteComponentProps, AppState> {
    componentDidMount() {
        this.getView();
    }

    componentDidUpdate(i) {
        let newPath = i.history.location.pathname;
        if (newPath !== i.location.pathname) {
            this.getView();
        }
    }

    getView() {
        let viewType = "charts",
            name = "",
            id = null,
            revisiontype = null;
        if (this.props.match.params.id || this.props.match.params.type) {
            const query = new URLSearchParams(this.props.match.params);
            viewType = query.get('type');
            name = query.get('name');
            id = Number(query.get('id'));
            if (this.props.match.params.revisiontype) {
                revisiontype = query.get('revisiontype');
            }
        }
        this.setState({
            viewType: revisiontype ? revisiontype : viewType,
            name: name,
            chartId: id
        });
    }

    handleCreateNewClick() {
        this.props.history.push("/charts//create/chart");
    }

    render() {
        Log.debug('Rendering Charts main', this.state);

        return (
            <div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                        <h2 className="mt0 mb05">{this.props.title}</h2>
                    </div>
                    <div className="ms-Grid-col ms-sm6 right">
                        <Can do="create" on="control">
                            {this.state && this.state.viewType !== "create" &&
                            <PrimaryButton text="New Chart" onClick={this.handleCreateNewClick.bind(this)} />
                            }
                        </Can>
                    </div>
                </div>

                <OpalBreadcrumbBar
                    location={this.props.location}
                    splitPoint={"charts"}
                    type={"Charts"}
                    history={this.props.history}
                    showOnRoot={false}
                />

                {(this.state && this.state.viewType === "charts") &&
                    <SmartChartList type={this.state && this.state.viewType} history={this.props.history} location={this.props.location} permissions={this.props.permissions} />
                }
                {this.state && this.state.viewType === "create" &&
                    <SmartChartEditor history={this.props.history} type={this.state && this.state.viewType} />
                }
                {(this.state && this.state.viewType === "revisions") &&
                    <SmartChartList chartId={this.state && this.state.chartId} name={this.state && this.state.name} type={this.state && this.state.viewType} permissions={this.props.permissions} history={this.props.history} location={this.props.location} />
                }
                {((this.state && this.state.viewType === "details") || (this.state && this.state.viewType === "revisiondetails")) &&
                    <SmartChartEditor chartId={this.state && this.state.chartId} type={this.state && this.state.viewType} history={this.props.history} />
                }
            </div>
        );
    }
}

export default SmartCharts;
