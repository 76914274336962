import * as React from 'react';
import { Log, Util, Toast } from "@voxfp/opal_ui_common";
import { TextField, Dropdown, PrimaryButton, DefaultButton, Label, MessageBar, MessageBarType, Dialog, DialogFooter, IDropdownOption } from 'office-ui-fabric-react';
import { PlaybookService } from '../../services/playbookService';
import { SmartTokenService } from '../../services/smartTokenService';
import { SmartRuleService } from '../../services/smartRuleService';
import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";

export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
    tokenId?: number;
    type?: string;
}

export interface AppState {
    playbooks: Array<any>;
    playbookId: number;
    playbookName: string;
    name: any;
    description: any;
    smartRules: Array<any>;
    smartRuleName: string;
    smartRuleId: any;
    saveError: boolean;
    errorMessage: string;
    formValid: boolean;
    hideCancelDialog: boolean;
    hideSavedDialog: boolean;
    status: string;
    loading: boolean;
}

export class SmartTokenEditor extends React.Component<AppProps, AppState>  {
    playbookService: PlaybookService = new PlaybookService();
    smartTokenService: SmartTokenService = new SmartTokenService();
    smartRuleService: SmartRuleService = new SmartRuleService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    componentDidMount() {
        if (this.props.tokenId) {
            const id = this.props.tokenId;
            this.getSmartToken(id);    
        }
        this.fetchPlaybooks();
        this.fetchSmartRules();
    }

    fetchPlaybooks() {
        this.setState({
            loading: true
        });
        this.playbookService.fetchPlaybooks().then((playbooks: any) => {
            this.setState({ 
                playbooks: playbooks
            });
            this.setState(prevState => ({
                playbooks: [ {key: 0, text: "Select a Playbook", disabled: true}, ...prevState.playbooks]
            }));
        }).catch(error => {
            Log.error("Error fetching Playbooks", error);
            Util.showToast(new Toast('Error fetching Playbooks.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    getSmartToken(id) {
        this.setState({
            loading: true
        });
        this.smartTokenService.getSmartToken(id).then((smartToken: any) => {
            this.setState({
                name: smartToken.label,
                description: smartToken.friendlyName,
                playbookId: smartToken.playbookId,
                playbookName: smartToken.playbookName,
                smartRuleName: smartToken.smartRule["attributes"]["name"],
                smartRuleId: smartToken.smartRule.id.toString(),
                status: smartToken.revision["workflow_state"]
            });
            this.fetchPlaybooks();
            this.fetchSmartRules();
        }).catch(error => {
            Log.error("Error fetching Token ID=" + id, error);
            Util.showToast(new Toast('Error fetching Token.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    fetchSmartRules() {
        this.setState({
            loading: true
        });
        this.smartRuleService.fetchSmartRules().then((smartRules: any) => {
            let smartRuleList = Util.orderOptions(smartRules, 'id', 'name');
            this.setState({ 
                smartRules: smartRuleList
            });
            this.setState(prevState => ({
                smartRules: [ {key: 0, text: "Select a Rule", disabled: true}, ...prevState.smartRules]
            }));
        }).catch(error => {
            Log.error('Error fetching Rules', error);
            this.setState({
                smartRules: []
            });
            Util.showToast(new Toast('Error fetching Rules.', MessageBarType.error));
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    handleNameInput(e) {
        this.setState({
            name: e.target.value
        });        
    }

    handleDescriptionInput(e) {
        this.setState({
            description: e.target.value
        });        
    }

    handleRuleChange(e) {
        const id = e.key;    
        this.setState({
            smartRuleId: id
        });
    }

    handlePlaybookChange(playbook) {
        this.setState({
            playbookId: playbook.key,
            playbookName: playbook.text
        });
    }

    validateForm() {
        if (this.state.playbookId !== null && this.state.playbookId > 0 && this.state.smartRuleId !== null && this.state.smartRuleId > 0 && this.state.name.length > 0 && this.state.description.length > 0) {
            this.setState({
                formValid: true
            });
            return true;
        }
        else {
            this.setState({
                formValid: false
            });
            return false;
        }
    }

    saveSmartToken(revision?) {
        return new Promise((resolve, reject) => {
            let isValid = this.validateForm();
            if (isValid) {
                let smartToken = {   
                    playbook_id: this.state.playbookId,         
                    name: this.state.name,
                    label: this.state.name,
                    description: this.state.description,
                    friendlyName: this.state.description,
                    smartRuleId: this.state.smartRuleId
                };

                this.smartTokenService.saveSmartToken(smartToken).then((success) => {  
                    if (!revision) {  
                        this.showSavedDialog();
                    }
                    resolve(success);
                }).catch(error => {
                    this.setState({
                        saveError: true,
                        errorMessage: error + ". "
                    });
                    Util.showToast(new Toast('Error saving Token.', MessageBarType.error, 4000));
                    reject(error);
                });
                
            }
            else {
                let error = "Please ensure all required fields are filled in.";
                Util.showToast(new Toast(error, MessageBarType.error));
                reject(error);
            }

        });
    }

    newRevision() {
        this.saveSmartToken("revision").then((token: any) => {
            window.location.assign("#/tokens//revisions/" + encodeURIComponent(token.label) + "/" + token.id + "//revisiondetails/" + token.revision.version + "/" + token.id);
            this.getSmartToken(token.id);
        });
    }

    updateSmartToken() {
        let isValid = this.validateForm();
        if (isValid) {
            let smartToken = {   
                playbook_id: this.state.playbookId,   
                label: this.state.name,
                friendlyName: this.state.description,
                smartRuleId: this.state.smartRuleId
            };

            this.smartTokenService.updateSmartToken(this.props.tokenId, smartToken).then((_success) => {    
                this.showSavedDialog();
            }).catch(error => {
                this.setState({
                    saveError: true,
                    errorMessage: error + ". "
                });
                Util.showToast(new Toast('Error saving Token.', MessageBarType.error, 4000));
            });
            
        }
        else {
            Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
        }
    }

    cancel() {
        if (this.props.tokenId) {
            this.okContinue();
        }
        else {
            this.setState({
                hideCancelDialog: false
            });
        }
    }

    closeDialog() {
        this.setState({
            hideCancelDialog: true
        });
    }

    showSavedDialog() {
        this.setState({
            hideSavedDialog: false
        });
    }

    okContinue() {
        window.location.assign('#/tokens');
    }

    reset() {
        return {
            playbooks: (this.state && this.state.playbooks) || [],
            name: '',
            description: '',
            playbookId: 0,
            playbookName: '',
            smartRules: [],
            smartRuleName: '',
            smartRuleId: 0,
            hideCancelDialog: true,
            hideSavedDialog: true,
            saveError: false,
            errorMessage: "",
            formValid: null,
            status: "",
            loading: false
        };
    }

    render() {
        Log.debug('Rendering Create Token', this.state);
        return (
            <div className="mt1">
                {this.state && this.state.playbooks && this.state.smartRules && this.state.loading === false &&
                    <div>
                        <div className="ms-Grid-row">                    
                            <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                                <TextField 
                                    readOnly={this.props.tokenId && this.state.status !== "Draft" ? true : false}
                                    label="Name" 
                                    id="name" 
                                    className="mb05"  
                                    errorMessage={this.state && this.state.name.length === 0 && this.state.formValid === false ? "* required" : null} 
                                    value={this.state.name} 
                                    onChange ={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                        this.handleNameInput(event);
                                    }}
                                />
                                <TextField 
                                    readOnly={this.props.tokenId && this.state.status !== "Draft" ? true : false}
                                    label="Description" 
                                    id="description" 
                                    className="mb05"  
                                    errorMessage={this.state && this.state.description.length === 0 && this.state.formValid === false ? "* required" : null} 
                                    value={this.state.description} 
                                    onChange ={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                        this.handleDescriptionInput(event);
                                    }} 
                                />
                                <Label>Playbook</Label>
                                <Dropdown responsiveMode={2}
                                    disabled={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? false : true}
                                    className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? "mb05" : "readOnlyStyle mb05"}
                                    placeholder=''
                                    notifyOnReselect={true}
                                    id='playbook'
                                    ariaLabel='Playbook'
                                    defaultSelectedKey={this.state && this.state.playbookId ? this.state.playbookId.toString() : 0}
                                    options={ this.state && this.state.playbooks ? this.state.playbooks : [] }
                                    onChange={
                                        (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                            this.handlePlaybookChange(item);
                                        }
                                    }
                                    errorMessage={this.state && this.state.playbookId === 0 && this.state.formValid === false ? "* required" : null} 
                                />
                                
                            </div>
                        </div>
                        
                        <div className="ms-Grid-row">
                        
                            <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                                {this.state && this.state.smartRules && !this.state.loading &&
                                    <div>
                                        <Label>Rule</Label>
                                        <Dropdown responsiveMode={2}
                                            disabled={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create" ? false : true}
                                            className={this.state.status === "Draft" || this.props.type === "newrevision" || this.props.type === "create"  ? "mb05" : "readOnlyStyle mb05"}
                                            placeholder=''
                                            notifyOnReselect={true}
                                            id='rule'
                                            ariaLabel='Rule'
                                            defaultSelectedKey={this.state.smartRuleId ? this.state.smartRuleId : 0}
                                            options={ this.state ? this.state.smartRules : [] }
                                            onChange={
                                                (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                                    this.handleRuleChange(item);
                                                }
                                            }
                                            errorMessage={this.state && this.state.smartRuleId === 0 && this.state.formValid === false ? "* required" : null} 
                                        />
                                    </div>
                                }

                                {this.props.type === "create" &&
                                    <div className="buttonRow right">                                   
                                        <DefaultButton text="Cancel" className="mt1" onClick={this.cancel.bind(this)}></DefaultButton>                         
                                        <PrimaryButton iconProps={{ iconName : "Save" }} className="mt1" text="Save" onClick={() => {this.saveSmartToken(); }}></PrimaryButton>
                                    </div>
                                }

                                {this.props.tokenId && this.state.status !== "Draft" &&
                                    <Can do="edit" on="control">
                                        <div className="buttonRow right">
                                            <PrimaryButton iconProps={{ iconName : "Tab" }} className="mt1" text="New Revision" onClick={() => {this.newRevision(); }}></PrimaryButton>
                                        </div>
                                    </Can>
                                }

                                {this.state && this.state.status === "Draft" && (this.props.type === "details" || this.props.type === "revisiondetails") &&
                                    <Can do="edit" on="control">
                                        <div className="buttonRow right">
                                            <DefaultButton text="Cancel" className="mt1" onClick={this.cancel.bind(this)}></DefaultButton>
                                            <PrimaryButton iconProps={{ iconName: "Save" }} className="mt1" text="Update" onClick={() => { this.updateSmartToken(); }}></PrimaryButton>
                                        </div>
                                    </Can>                 
                                }
                                {this.state && this.state.saveError === true &&
                                    <div>
                                        <br/>
                                        <MessageBar messageBarType={MessageBarType.error}>Error saving Token. {this.state.errorMessage}Please check form and try again.</MessageBar>
                                    </div>
                                }

                            </div>
                        </div>
                        <br/>
                    </div>
                }

                <Dialog
                    hidden={this.state && this.state.hideCancelDialog === null ? true : this.state && this.state.hideCancelDialog}
                    onDismiss={this.closeDialog.bind(this)}
                    dialogContentProps={{
                        title: 'Cancel changes',
                        subText: 'This will remove all changes. Are you sure you want to cancel?'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                    >
                    <DialogFooter>
                        <DefaultButton onClick={this.closeDialog.bind(this)} text="No" />
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Yes" />
                    </DialogFooter>
                </Dialog>

                <Dialog
                    hidden={this.state && this.state.hideSavedDialog === null ? true : this.state && this.state.hideSavedDialog}
                    dialogContentProps={{
                        title: 'Token saved',
                        subText: 'The Token was saved successfully.'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                    >
                    <DialogFooter>
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Ok" />
                    </DialogFooter>
                </Dialog>
    
            </div>
                
        );
    }
}

export default SmartTokenEditor;
