import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { SmartTemplateEditor } from './edit';
import { SmartTemplateList } from './list';
import { Log } from "@voxfp/opal_ui_common";
import {OpalBreadcrumbBar} from '../opalBreadcrumbBar/breadcrumb';

export interface AppProps {
    history: any;
    title: any;
    location?: any;
}

export interface AppState {
    viewType: string;
    name: string;
    templateId: number;
}

export class SmartTemplates extends React.Component<AppProps & RouteComponentProps, AppState> {

    componentDidMount() {
        this.getView();
    }

    componentDidUpdate(i) {
        let newPath = i.history.location.pathname;
        if (newPath !== i.location.pathname) {
            this.getView();
        } 
    }

    getView() {
        let viewType = "templates",
            name = "",
            id = null,
            revisiontype = null;
        if (this.props.match.params.id || this.props.match.params.type) {
            const query = new URLSearchParams(this.props.match.params);
            viewType = query.get('type');
            name = query.get('name');
            id = Number(query.get('id'));
            if (this.props.match.params.revisiontype) {
                revisiontype = query.get('revisiontype');
            }
        }
        this.setState({
            viewType: revisiontype ? revisiontype : viewType,
            name: name,
            templateId: id
        });
    }

    render() {
        Log.debug('Rendering Templates main', this.state);
        
        return (
            <div>

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                        <h2 className="mt0 mb05">{this.props.title}</h2>
                    </div>
                </div>

                <OpalBreadcrumbBar
                    location={this.props.location}
                    splitPoint={"templates"}
                    type={"Sub-Templates"}
                    history={this.props.history}
                    showOnRoot={false}
                />

               
                {(this.state && this.state.viewType === "templates") &&
                    <SmartTemplateList type={this.state && this.state.viewType} history={this.props.history} location={this.props.location} permissions={this.props.permissions} />
                }
                {(this.state && this.state.viewType === "revisions") &&
                    <SmartTemplateList templateId={this.state && this.state.templateId} name={this.state && this.state.name} type={this.state && this.state.viewType} permissions={this.props.permissions} history={this.props.history} location={this.props.location} />
                }
                {((this.state && this.state.viewType === "details") || (this.state && this.state.viewType === "revisiondetails")) &&
                    <SmartTemplateEditor templateId={this.state && this.state.templateId} type={this.state && this.state.viewType} />
                }
                    
            </div>
        ); 
    }
}

export default SmartTemplates;
