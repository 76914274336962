import {Model} from "@voxfp/opal_ui_common";
import {CurrentTemplateRevision} from "./currentTemplateRevision";

export class Template extends Model<Template> {

    name: string;
    shortName: string;
    version?: string;
    ooxml?: string;
    templateFamilyId?: number;
    currentRevision?: CurrentTemplateRevision;

    constructor(name?: string, shortName?: string, version?: string, ooxml?: string, templateFamilyId?: number, currentRevision?: CurrentTemplateRevision) {
        super(Template);
        this.name = name;
        this.shortName = shortName;
        this.version = version;
        this.ooxml = ooxml;
        this.templateFamilyId = templateFamilyId;
        this.currentRevision = currentRevision;
    }
}
