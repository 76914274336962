import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {PrimaryButton} from 'office-ui-fabric-react';
import { SmartRuleEditor } from './edit';
import { SmartRuleList } from './list';
import { Log } from "@voxfp/opal_ui_common";
import {OpalBreadcrumbBar} from '../opalBreadcrumbBar/breadcrumb';
import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";

export interface AppProps {
    history: any;
    title: any;
    location?: any;
}

export interface AppState {
    viewType: string;
    name: string;
    ruleId: number;
}

export class SmartRules extends React.Component<AppProps & RouteComponentProps, AppState> {

    componentDidMount() {
        this.getView();
    }

    componentDidUpdate(i) {
        let newPath = i.history.location.pathname;
        if (newPath !== i.location.pathname) {
            this.getView();
        } 
    }

    getView() {
        let viewType = "rules",
            name = "",
            id = null,
            revisiontype = null;
        if (this.props.match.params.id || this.props.match.params.type) {
            const query = new URLSearchParams(this.props.match.params);
            viewType = query.get('type');
            name = query.get('name');
            id = Number(query.get('id'));
            if (this.props.match.params.revisiontype) {
                revisiontype = query.get('revisiontype');
            }
        }
        this.setState({
            viewType: revisiontype ? revisiontype : viewType,
            name: name,
            ruleId: id
        });
    }

    handleCreateNewClick() {
        this.props.history.push("/rules//create/rule");
    }

    handleNewRevisionClick() {
        let path = this.props.location.pathname.split('details/');
        this.props.history.push("/rules//details/" + path[1] + "//newrevision/" + path[1]);
        this.getView();
    }

    render() {
        Log.debug('Rendering Rules main', this.state);
        
        return (
            <div>

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                        <h2 className="mt0 mb05">{this.props.title}</h2>
                    </div>
                    <div className="ms-Grid-col ms-sm6 right">
                        <Can do="create" on="control">
                            {this.state && this.state.viewType !== "create" &&
                                <PrimaryButton text="New Rule" onClick={this.handleCreateNewClick.bind(this)} />
                            }
                        </Can>  
                    </div>
                </div>

                <OpalBreadcrumbBar
                    location={this.props.location}
                    splitPoint={"rules"}
                    type={"Rules"}
                    history={this.props.history}
                    showOnRoot={false}
                />
               
                {(this.state && this.state.viewType === "rules") &&
                    <SmartRuleList type={this.state && this.state.viewType} history={this.props.history} location={this.props.location} />
                }
                {this.state && this.state.viewType === "details" &&
                    <SmartRuleEditor ruleId={this.state && this.state.ruleId} type={this.state && this.state.viewType} />
                }  
                {this.state && this.state.viewType === "create" &&
                    <SmartRuleEditor history={this.props.history} />
                }
                    
            </div>
        ); 
    }
}

export default SmartRules;
