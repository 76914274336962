import * as React from 'react';
import {Log, Util, Toast} from "@voxfp/opal_ui_common";
import {Toggle, Label, TextField, Dropdown, SpinButton, PrimaryButton, DefaultButton, MessageBar, MessageBarType, Dialog, DialogFooter, IDropdownOption} from 'office-ui-fabric-react';
import {SmartTokenService} from '../../services/smartTokenService';
import {SmartRuleService} from '../../services/smartRuleService';

export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
    ruleId?: number;
    type?: string;
}

export interface AppState {
    name: any;
    description: any;
    tokenTypes: Array<any>;
    tokenTypeId: any;
    tokenTypeName: string;
    tokenTypeAttributeOptions: Array<any>;
    tokenTypesError: boolean;
    saveError: boolean;
    errorMessage: string;
    formValid: boolean;
    hideCancelDialog: boolean;
    hideSavedDialog: boolean;
    smartRuleConstraints: any;
}

export class SmartRuleEditor extends React.Component<AppProps, AppState>  {

    smartTokenService: SmartTokenService = new SmartTokenService();
    smartRuleService: SmartRuleService = new SmartRuleService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    componentDidMount() {
        if (this.props.ruleId) {
            const id = this.props.ruleId;
            this.getSmartRule(id);    
        }
        else {
            this.fetchTokenTypes();
        }
    }

    getSmartRule(id) {
        Log.debug("Fetching Rule", id);
        this.smartRuleService.getSmartRule(id).then((data: any) => {
            this.setState({
                name: data.name,
                description: data.description,
                tokenTypeName: data.tokenTypeName,
                tokenTypeAttributeOptions: data.validators
            });
        }).catch(error => {
            Log.error("Error fetching Rule ID=" + id, error);
            Util.showToast(new Toast('Error fetching Rule.', MessageBarType.error));
        });
    }

    fetchTokenTypes() {
        this.smartRuleService.fetchTokenTypes().then((data: any) => {
            let tokenTypeList = Util.orderOptions(data, 'id', 'name');
            this.setState({ 
                tokenTypes: tokenTypeList,
                tokenTypesError: false
            });
            this.setState(prevState => ({
                tokenTypes: [ {key: 0, text: "Select a Token Type", disabled: true}, ...prevState.tokenTypes]
            })); 
        }).catch(error => {
            Log.error('Error fetching Token Types', error);
            this.setState({
                tokenTypes: [],
                tokenTypesError: true
            });
            Util.showToast(new Toast('Error fetching Token Types.', MessageBarType.error));
        });
    }

    handleNameInput(e) {
        this.setState({
            name: e.target.value
        });        
    }

    handleDescriptionInput(e) {
        this.setState({
            description: e.target.value
        });        
    }

    handleTokenTypeChange(option) {
        const id = option.key;    
        this.setState({
            tokenTypeId: id,
            tokenTypeName: option.text
        });
        this.smartRuleService.fetchTokenTypeAttributes(id).then((data: any) => {
            data.forEach(item => {
                item.disabled = true;
            });
            this.setState({
                tokenTypeAttributeOptions: data,
                smartRuleConstraints: []
            });
        }).catch(Log.error);
    }

    handleToggleChange(item, i, checked) {
        let tokenTypeAttributeOptions = [...this.state.tokenTypeAttributeOptions];
        tokenTypeAttributeOptions[i].disabled = !checked;
        this.setState({ tokenTypeAttributeOptions });
        if (!checked) {
            this.handleRemove(item.attributeId);
        }
        else {
            this.handleValidationOptionChanged("", item.attributeId);
        }
    }

    handleRemove(id) {
        let removeConstraint = this.state.smartRuleConstraints.filter(item => item.smart_rule_constraint_type_id !== id);
        this.setState({
            smartRuleConstraints: removeConstraint
        });
    }

    handleValidationOptionChanged(value, id) {

        let smartRuleConstraints = {
            smart_rule_constraint_type_id: id,
            value: value
        };

        let currentSmartRuleConstraints = this.state.smartRuleConstraints;

        let index = currentSmartRuleConstraints.findIndex(item => item.smart_rule_constraint_type_id === smartRuleConstraints.smart_rule_constraint_type_id );
        let found = index >= 0;

        if (!found && value !== undefined) {
            currentSmartRuleConstraints.push(smartRuleConstraints);
        } else if (found && value !== undefined) {
            currentSmartRuleConstraints.splice(index, 1, smartRuleConstraints);
        }

        let newSmartRuleConstraints = currentSmartRuleConstraints;

        this.setState({
            smartRuleConstraints: newSmartRuleConstraints
        });
    }

    validateForm() {
        if (this.state.tokenTypeId !== null && this.state.tokenTypeId > 0 && this.state.name.length > 0 && this.state.description.length > 0) {
            this.setState({
                formValid: true
            });
            return true;
        }
        else {
            this.setState({
                formValid: false
            });
            return false;
        }
    }

    saveSmartRule() {
        let isValid = this.validateForm();
        if (isValid) {
            let smartRule = {           
                name: this.state.name,
                description: this.state.description,
                tokenTypeId: this.state.tokenTypeId,
                smartRuleConstraints: this.state.smartRuleConstraints
            };
            Log.debug("Saving Rule", smartRule);
            this.smartRuleService.saveSmartRule(smartRule).then(() => {                   
                this.showSavedDialog();
            }).catch(error => {
                this.setState({
                    saveError: true,
                    errorMessage: error + ". "
                });
                Util.showToast(new Toast('Error saving Rule.', MessageBarType.error, 4000));
            });           
        }
        else {
            Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
        }
    }

    cancel() {
        if (this.props.ruleId) {            
            this.okContinue();
        }
        else {
            this.setState({
                hideCancelDialog: false
            });
        }
    }

    closeDialog() {
        this.setState({
            hideCancelDialog: true
        });
    }

    showSavedDialog() {
        this.setState({
            hideSavedDialog: false
        });
    }

    okContinue() {
        window.location.assign('#/rules');
    }

    reset() {
        return {
            name: '',
            description: '',
            tokenTypesError: false,            
            tokenTypes: null,
            tokenTypeId: 0,
            selectedTokenTypeName: '',
            tokenTypeName: '',
            tokenTypeAttributeOptions: [],
            hideCancelDialog: true,
            hideSavedDialog: true,
            saveError: false,
            errorMessage: "",
            formValid: null,
            smartRuleConstraints: []
        };
    }

    render() {
        Log.debug('Rendering Create Token', this.state);

        return (
            
            <div className="mt1">               
                <div>
                    <div className="ms-Grid-row">                    
                        <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                            <TextField 
                                readOnly={this.props.ruleId ? true : false}
                                label="Name" 
                                id="name" 
                                className="mb05"  
                                errorMessage={this.state && this.state.name.length === 0 && this.state.formValid === false ? "* required" : null} 
                                value={this.state.name} 
                                onChange ={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                    this.handleNameInput(event);
                                }}
                            />
                            <TextField 
                                readOnly={this.props.ruleId ? true : false}
                                label="Description" 
                                id="description" 
                                className="mb05"  
                                errorMessage={this.state && this.state.description.length === 0 && this.state.formValid === false ? "* required" : null} 
                                value={this.state.description} 
                                onChange ={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                    this.handleDescriptionInput(event);
                                }} 
                            />
                        </div>
                    </div>
                    
                    <div className="ms-Grid-row">
                    
                        <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">
                            {this.state && this.state.tokenTypeName && this.props.ruleId &&
                                <TextField 
                                    readOnly={this.props.ruleId ? true : false}
                                    label="Token Type" 
                                    id="tokeTypeName" 
                                    className="mb05"
                                    value={this.state.tokenTypeName}
                                />                                
                            }
                            {this.state && this.state.tokenTypes && !this.props.ruleId &&
                                <Dropdown responsiveMode={2}
                                    placeholder='Select'
                                    notifyOnReselect={true}
                                    label= 'Token Type'
                                    id='tokenType'
                                    ariaLabel='Token Type'
                                    defaultSelectedKey={this.state.tokenTypes ? this.state.tokenTypeId : 0}
                                    options={ this.state ? this.state.tokenTypes : [] }
                                    onChange={
                                        (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                            this.handleTokenTypeChange(item);
                                        }
                                    }
                                    className="mb05"  
                                    errorMessage={this.state && this.state.tokenTypeId === 0 && this.state.formValid === false ? "* required" : null} 
                                />
                            }

                            {this.state && this.state.tokenTypeAttributeOptions.length > 0 &&
                                <div>
                                    <br/>
                                    <h4 className="mb05">Validation Options</h4>
                                    {this.state && this.state.tokenTypeAttributeOptions.map((item, i) => (
                                        <div key={i} className="mb05 mt2">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm8 mb05">
                                                    <Label className="pt0">{item.description || item.name}</Label>
                                                </div>
                                                <div className="ms-Grid-col ms-sm4 right">
                                                    <div className="toggleWrap">
                                                        {item.text &&
                                                            <Toggle
                                                                disabled={item.name ? true : false}
                                                                checked={this.state.tokenTypeAttributeOptions[i].disabled !== true ? true : false}
                                                                onChange={(_event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                                                this.handleToggleChange(item, i, checked);
                                                            }} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toggleItem">
                                                {(item.dataType === "Decimal") || 
                                                (item.dataType === "Integer") ?
                                                    <div className="spinner">
                                                        <div className={this.state && item.value.length === 0 && this.state.formValid === false &&
                                                            this.state.tokenTypeAttributeOptions[i].disabled !== true ? "spinner-wrapper e-error" : 'spinner-wrapper'}>
                                                            <SpinButton
                                                                disabled={this.state.tokenTypeAttributeOptions[i].disabled || (this.props.ruleId && this.props.type !== "newrevision") ? true : false}
                                                                placeholder={item.description}
                                                                value={(this.state && this.state.tokenTypeAttributeOptions[i].value) || ""}
                                                                onChange={(value) => {
                                                                    this.handleValidationOptionChanged(value, item.attributeId);
                                                                    item.value = value;
                                                                }}
                                                                onIncrement={(value) => {
                                                                    let newValue = "0";
                                                                    if (value === "") {
                                                                        newValue = "0";
                                                                    }
                                                                    else if (item.dataType === "Decimal") {
                                                                        let decValue = Number(value) + 0.1; 
                                                                        newValue = parseFloat(String(decValue)).toFixed(1); 
                                                                    }
                                                                    else {
                                                                        let norValue = Number(value) + 1;
                                                                        newValue = String(norValue);
                                                                    }
                                                                    this.handleValidationOptionChanged(newValue, item.attributeId);
                                                                    item.value = newValue;
                                                                    return String(newValue);
                                                                } }
                                                                onDecrement={(value) => {
                                                                    let newValue = "0";
                                                                    if (value === "") {
                                                                        newValue = "0";
                                                                    }
                                                                    else if (item.dataType === "Decimal") {
                                                                        let decValue = Number(value) - 0.1; 
                                                                        newValue = parseFloat(String(decValue)).toFixed(1); 
                                                                    }
                                                                    else {
                                                                        let norValue = Number(value) - 1;
                                                                        newValue = String(norValue);
                                                                    }
                                                                    this.handleValidationOptionChanged(newValue, item.attributeId);
                                                                    item.value = newValue;
                                                                    return String(newValue);
                                                                } }                                                            
                                                            />
                                                        </div>
                                                        {this.state && item.value.length === 0 && this.state.formValid === false &&
                                                        this.state.tokenTypeAttributeOptions[i].disabled !== true ? <div className="error error-message">* required</div> : null} 
                                                    </div>
                                                    :
                                                    item.dataType === "Boolean" ?
                                                        null
                                                    :
                                                    item.dataType === undefined && item.value &&
                                                    <TextField type={'text'}
                                                        readOnly={this.props.ruleId && this.props.type !== "newrevision" ? true : false}
                                                        disabled={this.state.tokenTypeAttributeOptions[i].disabled !== true ? false : true}
                                                        id={item.attributeId}
                                                        ariaLabel={item.text}
                                                        placeholder={item.description} 
                                                        value={this.state && this.state.tokenTypeAttributeOptions[i].value}
                                                        errorMessage={
                                                            this.state && 
                                                            item.value.length === 0 &&
                                                            this.state.formValid === false &&
                                                            this.state.tokenTypeAttributeOptions[i].disabled !== true ? "* required" : null}                                                        
                                                        onChange ={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
                                                            this.handleValidationOptionChanged(value, item.attributeId);
                                                            item.value = value;
                                                        }} 
                                                    />
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                            
                            {this.props.ruleId ? 
                                null
                                : 
                                <div className="buttonRow right">
                                    <DefaultButton text="Cancel" className="mt1" onClick={this.cancel.bind(this)}></DefaultButton>
                                    <PrimaryButton iconProps={{ iconName: "Save" }} className="mt1" text="Save" onClick={() => { this.saveSmartRule(); }}></PrimaryButton>
                                </div>
                            }
                            {this.state && this.state.saveError === true &&
                                <div>
                                    <br/>
                                    <MessageBar messageBarType={MessageBarType.error}>Error saving Rule. {this.state.errorMessage}Please check form and try again.</MessageBar>
                                    </div>
                            }

                        </div>
                    </div>

                    <br/>
                    
                </div>

                <Dialog
                    hidden={this.state && this.state.hideCancelDialog === null ? true : this.state && this.state.hideCancelDialog}
                    onDismiss={this.closeDialog.bind(this)}
                    dialogContentProps={{
                        title: 'Cancel changes',
                        subText: 'This will remove all changes. Are you sure you want to cancel?'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                    >
                    <DialogFooter>
                        <DefaultButton onClick={this.closeDialog.bind(this)} text="No" />
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Yes" />
                    </DialogFooter>
                </Dialog>

                <Dialog
                    hidden={this.state && this.state.hideSavedDialog === null ? true : this.state && this.state.hideSavedDialog}
                    dialogContentProps={{
                        title: 'Token saved',
                        subText: 'The Token was saved successfully.'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                    >
                    <DialogFooter>
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Ok" />
                    </DialogFooter>
                </Dialog>
    
            </div>
                
        );
    }
}

export default SmartRuleEditor;
