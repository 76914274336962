import * as React from 'react';
import {Breadcrumb} from 'office-ui-fabric-react';

export interface AppProps {
    location: any;
    splitPoint: string;
    type: string;
    history: any;
    showOnRoot: boolean;
}

export interface AppState {
    breadcrumbs: Array<any>;
}

export class OpalBreadcrumbBar extends React.Component<AppProps, AppState> {
    
    constructor(props, state) {
        super(props, state);
        this.state = {
            breadcrumbs: null
        };
    }

    componentDidMount() {
        this.pathToBreadCrumbs(this.props.location.pathname);
    }

    componentDidUpdate(i) {
        let newPath = i.history.location.pathname;
        if (newPath !== i.location.pathname) {
            this.pathToBreadCrumbs(newPath);
        } 
    }

    pathToBreadCrumbs(path) {
        path = path.split(this.props.splitPoint).pop();
        let levels = path.split('//');
        let breadcrumbs = [
            {text: this.props.type, onClick: () => {
                let breadcrumbs = this.state.breadcrumbs;
                if (breadcrumbs.length > 1) {
                    breadcrumbs = breadcrumbs.slice(0, 1);
                    this.setState({
                        breadcrumbs: breadcrumbs
                    });
                    this.props.history.push("/" + this.props.splitPoint);
                }
            }}
        ];
        if (levels[1] !== '') {
            for (let iLevel = 1; iLevel < levels.length; iLevel++) {
                let handle = levels[iLevel].split('/');
                let breadcrumbtype = handle[0];
                let redirect = null;
                if (breadcrumbtype === "create") {
                    redirect = "/" + this.props.splitPoint + "//" + levels[1];
                    handle[1] = "New " + handle[1];
                }
                if (breadcrumbtype === "details") {
                    redirect = "/" + this.props.splitPoint + "//" + levels[1];
                    handle[1] = handle[1] + " - Details";
                }
                else if (breadcrumbtype === "revisions") {
                    redirect = "/" + this.props.splitPoint + "//" + levels[1];
                    handle[1] = handle[1] + " - Version History";
                }
                else if (breadcrumbtype === "newrevision") {
                    redirect = "/" + this.props.splitPoint + "//" + levels[1];
                    handle[1] = handle[1] + " - New Revision";
                }
                else if (breadcrumbtype === "revisiondetails") {
                    redirect = "/" + this.props.splitPoint + "//" + levels[1] + "//" + levels[2];
                    handle[1] = "Version " + handle[1] + " - Details";
                }
                breadcrumbs.push(this.createBreadCrumb(handle, redirect));
            }
        }
        this.setState({
            breadcrumbs: breadcrumbs
        });
    }

    createBreadCrumb(handle, redirect) {
        let name = handle[1];
        let data = {
            text: decodeURIComponent(name),
            onClick: () => {
                
                let breadcrumbs = this.state.breadcrumbs;
                if (breadcrumbs[breadcrumbs.length - 1] === data) {
                    //return false;
                }
                for (let iBreadCrumb = 0; iBreadCrumb < breadcrumbs.length; iBreadCrumb++) {
                    if (breadcrumbs[iBreadCrumb] === data) {
                        breadcrumbs = breadcrumbs.slice(0, iBreadCrumb + 1);
                        this.setState({breadcrumbs: breadcrumbs});
                        this.props.history.push(redirect);
                    }
                }
            }
        };
        return data;
    }

    render() {
        let showPoint = this.props.showOnRoot ? 0 : 1;
        return (
            this.state.breadcrumbs && this.state.breadcrumbs.length > showPoint &&
            <Breadcrumb items={this.state.breadcrumbs} maxDisplayedItems={5} />
        );
    }

}

export default OpalBreadcrumbBar;
