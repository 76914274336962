import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {PrimaryButton} from 'office-ui-fabric-react';
import { SmartRepeatEditor } from './edit';
import { SmartRepeatList } from './list';
import { Log } from "@voxfp/opal_ui_common";
import {OpalBreadcrumbBar} from '../opalBreadcrumbBar/breadcrumb';
import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";

export interface AppProps {
    history: any;
    title: any;
    location?: any;
}

export interface AppState {
    viewType: string;
    name: string;
    repeatId: number;
}

export class SmartRepeats extends React.Component<AppProps & RouteComponentProps, AppState> {

    componentDidMount() {
        this.getView();
    }

    componentDidUpdate(i) {
        let newPath = i.history.location.pathname;
        if (newPath !== i.location.pathname) {
            this.getView();
        } 
    }

    getView() {
        let viewType = "repeats",
            name = "",
            id = null,
            revisiontype = null;
        if (this.props.match.params.id || this.props.match.params.type) {
            const query = new URLSearchParams(this.props.match.params);
            viewType = query.get('type');
            name = query.get('name');
            id = Number(query.get('id'));
            if (this.props.match.params.revisiontype) {
                revisiontype = query.get('revisiontype');
            }
        }
        this.setState({
            viewType: revisiontype ? revisiontype : viewType,
            name: name,
            repeatId: id
        });
    }

    handleCreateNewClick() {
        this.props.history.push("/repeats//create/repeat");
    }

    handleNewRevisionClick() {
        let path = this.props.location.pathname.split('details/');
        this.props.history.push("/repeats//details/" + path[1] + "//newrevision/" + path[1]);
        this.getView();
    }

    render() {
        Log.debug('Rendering Repeats main', this.state);
        
        return (
            <div>

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                        <h2 className="mt0 mb05">{this.props.title}</h2>
                    </div>
                    <div className="ms-Grid-col ms-sm6 right">
                        <Can do="create" on="control">
                            {this.state && this.state.viewType !== "create" &&
                                <PrimaryButton text="New Repeat" onClick={this.handleCreateNewClick.bind(this)} />
                            }  
                        </Can>
                    </div>
                </div>

                <OpalBreadcrumbBar
                    location={this.props.location}
                    splitPoint={"repeats"}
                    type={"Repeats"}
                    history={this.props.history}
                    showOnRoot={false}
                />

               
                {(this.state && this.state.viewType === "repeats") &&
                    <SmartRepeatList type={this.state && this.state.viewType} history={this.props.history} location={this.props.location} permissions={this.props.permissions} />
                }
                {(this.state && this.state.viewType === "revisions") &&
                    <SmartRepeatList repeatId={this.state && this.state.repeatId} name={this.state && this.state.name} type={this.state && this.state.viewType} permissions={this.props.permissions} history={this.props.history} location={this.props.location} />
                }
                {((this.state && this.state.viewType === "details") || (this.state && this.state.viewType === "revisiondetails")) &&
                    <SmartRepeatEditor repeatId={this.state && this.state.repeatId} type={this.state && this.state.viewType} />
                }   
                {this.state && this.state.viewType === "create" &&
                    <SmartRepeatEditor history={this.props.history} type={this.state && this.state.viewType} />
                }
                    
            </div>
        ); 
    }
}

export default SmartRepeats;
