import * as React from "react";
import {IContextualMenuItem, Link, MessageBar, MessageBarType} from 'office-ui-fabric-react';
import {DetailsListUtils, Log, Util, Toast, OpalTable} from "@voxfp/opal_ui_common";
import {SmartChartService} from "../../services/smartChartService";
import {WorkflowEventService} from "../../services/workflowEventService";

export interface AppProps {
    chartId?: number;
    name?: string;
    type: string;
    history: any;
    location?: any;
    permissions: any;
}

export interface AppState {
    listItems: Array<any>;
    columns: Array<any>;
    isDataLoaded: boolean;
    isError: boolean;
}

export class SmartChartList extends React.Component<AppProps, AppState> {
    smartChartService: SmartChartService = new SmartChartService();
    workflowEventService: WorkflowEventService = new WorkflowEventService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    reset() {
        return ({
            listItems: [],
            columns: [],
            isDataLoaded: false,
            isError: false
        });
    }

    componentDidMount() {
        this.loadList();
    }

    componentDidUpdate(i) {
        let newPath = i.history.location.pathname;
        if (newPath !== i.location.pathname) {
            this.loadList();
        }
    }

    loadList() {
        if (this.props.chartId > 0) {
            this.loadChartRevisionsList();
        }
        else {
            this.loadChartList();
        }
    }

    loadChartList() {
        this.fetchSmartCharts();
        this.setChartColumns();
    }

    loadChartRevisionsList() {
        this.setState({
            listItems: []
        });
        this.fetchSmartChartRevisions(this.props.chartId);
        this.setChartRevisionsColumns();
    }

    fetchSmartCharts() {
        Log.debug("Fetching Charts", this.state);
        this.smartChartService.fetchSmartCharts().then((items: any) => {
            DetailsListUtils.sortItems(items, "updatedAt", true);
            this.setState({
                listItems: items,
                isDataLoaded: true,
                isError: false
            });
        }).catch(error => {
            this.setState({
                isDataLoaded: true,
                isError: true
            });
            Util.showToast(new Toast('Error fetching Charts. ' + error, MessageBarType.error));
        });
    }

    fetchSmartChartRevisions(id) {
        Log.debug("Fetching Chart Revisions", this.state);
        this.smartChartService.fetchSmartChartRevisions(id).then((items: any) => {
            DetailsListUtils.sortItems(items, "updatedAt", true);
            this.setState({
                listItems: items,
                isDataLoaded: true,
                isError: false
            });
        }).catch(error => {
            this.setState({
                isDataLoaded: true,
                isError: true
            });
            Util.showToast(new Toast('Error fetching Chart Revisions. ' + error, MessageBarType.error));
        });
    }

    setChartColumns() {
        const createAbility = this.props.permissions.can("create", "control");
        const templateListColumns = [
            {
                field: "label",
                headerText: "Name",
                width: "15",
                itemType: "Link",
                customAttributes: {
                    urlTemplate: "#/charts//details/{label}/{id}"
                }
            },
            {
                field: "playbook.name",
                headerText: "Playbook",
                width: "15",
                filter: "Checkbox"
            },
            {
                field: "smartChartType.name",
                headerText: "Type",
                width: "15",
                filter: "Checkbox",
            },
            {
                field: "currentRevision.version",
                headerText: "Version",
                width: "10",
                filter: "Checkbox"
            },
            {
                field: "currentRevision.workflow_state",
                headerText: "Status",
                filter: "Checkbox",
                width: "15",
            },
            {
                field: "updatedAt",
                headerText: "Last Edit",
                width: "15",
                type: "date",
                format: "yyyy-MM-dd"
            },
            {
                field: "options",
                headerText: "Options",
                width: "15",
                disableSorting: true,
                disableFiltering: true,
                itemType: "Dropdown",
                customAttributes: {
                    buttonText: "Options",
                    dropdownTemplate: [{
                        sectionProps: [{
                            title: 'View',
                            items: [
                                {
                                    text: 'Version History',
                                    onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                        this.handleVersionHistoryClick(i.item);
                                    }
                                }
                            ]
                        },
                            {
                                title: createAbility ? 'Manage' : '',
                                items: [
                                    {
                                        onRender: (i: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
                                            return (
                                                createAbility &&
                                                <Link disabled={i.item.currentRevision["workflow_state"] === "Draft"} onClick={() => {
                                                    this.handleNewRevisionClick(i.item);
                                                    dismissMenu();
                                                }} className="opal-ContextualMenu-link">
                                                  <div className="opal-ContextualMenu-linkContent">
                                                    <span className={i.item.currentRevision["workflow_state"] === "Draft" ? "opal-ContextualMenu-itemText disabled" : "opal-ContextualMenu-itemText"}>
                                                        New Revision
                                                    </span>
                                                  </div>
                                                </Link>
                                            );
                                        }
                                    },
                                ]
                            }]
                    }]
                }
            }
        ];
        this.setState({
            columns: templateListColumns
        });
    }

    setChartRevisionsColumns() {
        const readAbility = this.props.permissions.can("read", "control");
        const templateListColumns = [
            {
                field: "version",
                headerText: "Version",
                width: "15",
                itemType: "Link",
                customAttributes: {
                    urlTemplate: "#/charts//revisions/" + encodeURIComponent(this.props.name) + "/" + this.props.chartId + "//revisiondetails/{version}/{details.id}",
                    linkTextTemplate: "Version {version}"
                }
            },

            {
                field: "author",
                headerText: "Author",
                width: "20",
                filter: "Checkbox"
            },
            {
                field: "updatedAt",
                headerText: "Last Edit",
                width: "20",
                type: "date",
                format: "yyyy-MM-dd"
            },
            {
                field: "templatesCount",
                headerText: "Templates",
                width: "15",
            },
            {
                field: "workflowState",
                headerText: "State",
                width: "15",
                filter: "Checkbox"
            },
            {
                field: "options",
                headerText: readAbility ? "Options" : "",
                width: readAbility ? "15" : "0",
                disableSorting: true,
                disableFiltering: true,
                itemType: "Dropdown",
                customAttributes: {
                    buttonText: "Options",
                    dropdownTemplate: [{
                        sectionProps: [{
                            title: 'Actions',
                            items: [
                                {
                                    onRender: (i: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
                                        return (
                                            i.item.workflowEvents.map(
                                                (workflowItem, _key) => {
                                                    return (
                                                        <Link onClick={() => {
                                                            this.handleWorkflowState(i.item.id, workflowItem.name);
                                                            dismissMenu();
                                                        }} className="opal-ContextualMenu-link">
                                                            <div className="opal-ContextualMenu-linkContent">
                                                                <span className="opal-ContextualMenu-itemText">
                                                                    {workflowItem.description}
                                                                </span>
                                                            </div>
                                                        </Link>
                                                    );
                                                }
                                            )
                                        );
                                    }
                                }
                            ]
                        }]
                    }]
                }
            }
        ];
        this.setState({
            columns: templateListColumns
        });
    }

    handleVersionHistoryClick(item) {
        let name = encodeURIComponent(item.label);
        this.props.history.push("/charts//revisions/" + name + "/" + item.id);
    }

    getSmartChart(id) {
        return new Promise((resolve, reject) => {
            this.smartChartService.getSmartChart(id).then((smartChart: any) => {
                resolve(smartChart);
            }).catch(error => {
                Log.error("Error fetching Chart ID=" + id, error);
                Util.showToast(new Toast('Error fetching Chart.', MessageBarType.error));
                reject(error);
            });
        });
    }

    createSmartChartRevision(chart) {
        return new Promise((resolve, reject) => {
            let smartChart = {
                playbookId: chart.playbook.id,
                label: chart.label,
                friendlyName: chart.friendlyName,
                smartChartTypeId: chart.smartChartType.id
            };

            this.smartChartService.saveSmartChart(smartChart).then((success) => {
                resolve(success);
            }).catch(error => {
                Util.showToast(new Toast('Error creating a revision. ' + error, MessageBarType.error, 4000));
                reject(error);
            });
        });
    }

    handleNewRevisionClick(item) {
        this.getSmartChart(item.id).then((smartChart) => {
            this.createSmartChartRevision(smartChart).then((chart: any) => {
                window.location.assign("#/charts//revisions/" + encodeURIComponent(chart.label) + "/" + chart.id + "//revisiondetails/" + chart.revision.version + "/" + chart.id);
            });
        });
    }

    handleWorkflowState(id, workflowEvent) {
        this.setItemState(id, workflowEvent);
    }

    setItemState(id, state) {
        this.workflowEventService.setWorkflowEvent(id, state).then((_data) => {
            Util.showToast(new Toast('Chart workflow set to ' + state + '.', MessageBarType.success));
            this.loadList();
        })
            .catch((error) => {
                Util.showToast(new Toast('Error setting workflow. ' + error, MessageBarType.error));
            });
    }

    render() {
        Log.debug('Rendering Chart List', this.state);

        return (
            <div className="mt1">
                {this.state && this.state.listItems.length > 0 && this.state.columns !== [] &&
                <OpalTable
                  listData={this.state && this.state.listItems}
                  listColumns={this.state && this.state.columns}
                  allowPaging={true}
                  pageSettings={{
                      pageCount: 5,
                      pageSizes: true,
                      pageSize: 10
                  }}
                />
                }
                {((this.state && this.state.isError) || (this.state && this.state.listItems.length === 0)) && this.state.isDataLoaded &&
                <MessageBar>There are no items to show.</MessageBar>
                }
            </div>
        );
    }
}

export default SmartChartList;
