import {Log, Util} from "@voxfp/opal_ui_common";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class PlaybookService {

    fetchPlaybooks(withTemplate?, withToken?, withSwitch?, withConditional?, withTag?) {
        let showAll = !withTemplate && !withToken && !withSwitch && !withConditional && !withTag ? true : false;
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.Playbooks, null, null, true).then((data: any) => {
                let playbookList = [];
                data.forEach((item) => {
                    if (
                        withTemplate ? item.contentControlRevisionCounts["smart-template"] : '' &&
                        withToken ? item.contentControlRevisionCounts["smart-token"] : '' &&
                        withSwitch ? item.contentControlRevisionCounts["smart-switch"] : '' &&
                        withConditional ? item.contentControlRevisionCounts["smart-condition"] : '' &&
                        withTag ? item.contentControlRevisionCounts["smart-tag"] : '' || 
                        showAll
                        ) {
                        playbookList.push(item);
                    }
                });
                let playbookListOptions = Util.orderOptions(playbookList, 'id', 'name');
                resolve(playbookListOptions);
            }).catch(error => {   
                Log.error("PlaybookService:fetchPlaybooks", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchContentControlRevisionsFromPlaybook(playbookId) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.PlaybookContentControlRevisions, playbookId, null, true)
            .then((contentControlRevisions: any) => {
                let contentControlRevisionList = Util.orderOptions(contentControlRevisions, 'id', 'label');
                resolve(contentControlRevisionList);
            }).catch(error => {    
                Log.error("PlaybookService:fetchContentControlRevisionsFromPlaybook", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchComposableContentControlRevisionsByTypeFromPlaybook(playbookId, type) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.PlaybookComposableContentControlRevisionsByType, {id: playbookId, type: type}, null, true)
            .then((contentControlRevisions: any) => {
                let contentControlRevisionList = Util.orderOptions(contentControlRevisions, 'id', 'label');
                resolve(contentControlRevisionList);
            }).catch(error => {    
                Log.error("PlaybookService:fetchComposableContentControlRevisionsByTypeFromPlaybook", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchComposableTemplatesFromPlaybook(playbookId) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.PlaybookComposableTemplates, playbookId, null, true)
            .then((templates: any) => {
                let templatesList = Util.orderOptions(templates, 'id', 'name');
                resolve(templatesList);
            }).catch(error => {    
                Log.error("PlaybookService:fetchComposableTemplatesFromPlaybook", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
