import * as React from 'react';
import {MessageBar, MessageBarType} from 'office-ui-fabric-react';
import {DetailsListUtils, Log, Util, Toast, OpalTable} from "@voxfp/opal_ui_common";
import {SmartTemplateService} from '../../services/smartTemplateService';
import {WorkflowEventService} from '../../services/workflowEventService';

export interface AppProps {
    templateId?: number;
    name?: string;
    type: string;
    history: any;
    location?: any;
    permissions: any;
}

export interface AppState {
    listItems: Array<any>;
    columns: Array<any>;
    isDataLoaded: boolean;
    isError: boolean;
}

export class SmartTemplateList extends React.Component<AppProps, AppState>  {

    smartTemplateService: SmartTemplateService = new SmartTemplateService();
    workflowEventService: WorkflowEventService = new WorkflowEventService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    reset() {
        return ({
            listItems: [],
            columns: [],
            isDataLoaded: false,
            isError: false
        });
    }

    componentDidMount() {
        this.loadTemplateList();
    }

    componentDidUpdate(i) {
        let newPath = i.history.location.pathname;
        if (newPath !== i.location.pathname) {
            this.loadTemplateList();
        } 
    }

    loadTemplateList() {
        this.fetchSmartTemplates();
        this.setTemplateColumns();
    }


    fetchSmartTemplates() {
        Log.debug("Fetching Templates", this.state);
        this.smartTemplateService.fetchSmartTemplates().then((items: any) => {
            DetailsListUtils.sortItems(items, "updatedAt", true);
            this.setState({
                listItems: items,
                isDataLoaded: true,
                isError: false
            });         
        }).catch(error => {
            this.setState({
                isDataLoaded: true,
                isError: true
            });
            Util.showToast(new Toast('Error fetching Templates. ' + error, MessageBarType.error));          
        });
    }

    setTemplateColumns() {
        const templateListColumns = [
            {
                field: "label",
                headerText: "Name",
                width: "15",
                itemType: "Link",
                customAttributes: {
                    urlTemplate: "#/templates//details/{label}/{id}"
                }
            },
            {
                field: "friendlyName",
                headerText: "Friendly Name",
                width: "15",
            },
            {
                field: "playbook",
                headerText: "Playbook",
                width: "15",
                filter: "Checkbox"
            },
            {
                field: "currentRevision.version",
                headerText: "Version",
                width: "10",
                filter: "Checkbox"
            },
            {
                field: "currentRevision.workflow_state",
                headerText: "Status",
                width: "10",
                filter: "Checkbox"
            },
            {
                field: "updatedAt",
                headerText: "Last Edit",
                width: "15",
                type: "date",
                format: "yyyy-MM-dd"
            }
        ];
        this.setState({
            columns: templateListColumns
        });
    }

    render() {

        Log.debug('Rendering Smart-Templates List', this.state);

        return (
            <div className="mt1">
                {this.state && this.state.listItems.length > 0 && this.state.columns !== [] &&
                    <OpalTable
                        listData={this.state && this.state.listItems}
                        listColumns={this.state && this.state.columns}
                        allowPaging={true}
                        pageSettings={{
                            pageCount: 5,
                            pageSizes: true,
                            pageSize: 10
                        }}
                    />
                }
                {((this.state && this.state.isError) || (this.state && this.state.listItems.length === 0)) && this.state.isDataLoaded &&
                    <MessageBar>There are no items to show.</MessageBar>
                }
            </div>
        );
    }
}

export default SmartTemplateList;




