export class CommonUtils {

    static FormatError(error) {

        try {
            let opalError = JSON.parse(error);
            if (opalError) {
                error = opalError.errors[0].message;
            }
        }
        catch {
            return error;
        }

        return error;
       
    }

}

export default CommonUtils;
